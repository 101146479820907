import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'
import {Box} from '@mui/material'
import React, {useMemo, useState, useCallback} from 'react'
import {useParams} from 'react-router'

import {mapToQuestionItems, doesAnswerCompleteCategory} from '../common/domain/questionnaire'
import {PathParameters} from '../common/routing'
import {LevelCompletion} from '../components/levelReward/LevelCompletion'
import {QuestionDetail} from '../components/questionnaire/QuestionDetail'
import {Questionnaire} from '../components/questionnaire/Questionnaire'
import {useQuestions} from '../hooks/api/useQuestions'
import {useSubmitAnswer} from '../hooks/api/useSubmitAnswer'
import {
  useQuestionDifficultyParam,
  useStringSearchParameter
} from '../hooks/urlParameters/useSearchParameter'
import {QuestionAnswer} from '../types'

export const QuestionnaireContainer: React.FC = () => {
  const {plantId} = useParams<Pick<PathParameters, 'plantId'>>()
  if (!plantId) throw new Error('The plantId is not set in the URL.')
  const questionList = useQuestions()
  const [difficulty, setDifficulty] = useQuestionDifficultyParam()

  const [selectedQuestionId, setSelectedQuestionId] = useStringSearchParameter('selectedQuestionId')
  const selectedQuestion = useMemo(
    () => mapToQuestionItems(questionList.data ?? []).find(({id}) => id === selectedQuestionId),
    [questionList.data, selectedQuestionId]
  )
  const [showCompletionDialog, setShowCompletionDialog] = useState(false)
  const onSuccess = useCallback(
    (result: QuestionAnswer) => {
      if (doesAnswerCompleteCategory(result, questionList.data ?? [])) {
        setShowCompletionDialog(true)
      }
    },
    [questionList.data]
  )
  const {mutate: submitAnswer} = useSubmitAnswer(onSuccess)
  const onAnswerSelected = (id: string, answer: AnswerStatus) => {
    submitAnswer({
      plantId,
      questionId: id,
      answer
    })
  }

  const onDifficultyChanged = (questionDifficulty: QuestionDifficulty) => {
    setDifficulty(questionDifficulty)
  }

  return (
    <Box mx={1.5}>
      {selectedQuestion ? (
        <QuestionDetail
          selectedQuestion={selectedQuestion}
          onClose={() => setSelectedQuestionId(undefined)}
          onSelected={onAnswerSelected}
        />
      ) : (
        <Questionnaire
          questionList={questionList}
          onAnswerSelected={onAnswerSelected}
          boxSx={{flex: 1}}
          difficulty={difficulty}
          onDifficultyChanged={onDifficultyChanged}
        />
      )}
      {showCompletionDialog && (
        <LevelCompletion
          open={showCompletionDialog}
          sectionCompleted={difficulty}
          onOpenChange={(open) => setShowCompletionDialog(open)}
        />
      )}
    </Box>
  )
}
