import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useQueryClient, useMutation} from 'react-query'

import {putAnswer} from '../../common/backend'
import {QuestionAnswer} from '../../types'

export const useSubmitAnswer = (onSuccess?: (answer: QuestionAnswer) => void) => {
  const {t} = useTranslation()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    ({plantId, questionId, answer}: QuestionAnswer) => putAnswer({plantId, questionId, answer}),
    {
      onSuccess: (result) => {
        onSuccess?.(result)
        void client.invalidateQueries()
      },
      onError: () => {
        enqueueSnackbar(t('questionnaire.questionSubmitted.error'), {variant: 'error'})
      }
    }
  )
}
