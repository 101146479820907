import {Question, Section, Topic} from '../../types'

export const getSectionTopic = (section: Section, topicId: string): Topic | undefined =>
  section.topics.find(({id}) => topicId === id)

export const getSectionById = (sections: Section[], sectionId: string): Section | undefined =>
  sections.find(({id}) => id === sectionId)

export const getSectionIds = (sections: Section[]): string[] => sections.map(({id}) => id)

const getSelectedSections = (sections: Section[], selectedSectionIds?: string[]): Section[] =>
  sections.filter(({id}) =>
    selectedSectionIds && selectedSectionIds.length ? selectedSectionIds.includes(id) : true
  )

const getTopics = (sections: Section[], selectedSectionIds?: string[]): Topic[] =>
  getSelectedSections(sections, selectedSectionIds).flatMap(({topics}) => topics)

export const getTopicIds = (sections: Section[], selectedSectionIds?: string[]): string[] =>
  getTopics(sections, selectedSectionIds).map(({id}) => id)

export const getAllTopicIds = (sections: Section[]): string[] =>
  sections.flatMap(({topics}) => topics).map(({id}) => id)

export const getQuestionIds = (
  sections: Section[],
  sectionIds?: string[],
  topicIds?: string[]
): string[] =>
  sections
    .filter((s) => !sectionIds || !sectionIds.length || sectionIds.includes(s.id))
    .flatMap((s) => s.topics)
    .filter((t) => !topicIds || !topicIds.length || topicIds.includes(t.id))
    .flatMap((t) => t.questions as Question[])
    .map((q) => q.id)
