import {User} from '@hconnect/apiclient'
import {UserDto} from '@hconnect/common/types'
import {sortBy} from 'lodash'

import {RequestFunc} from '../../types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'

export type GetPomUsersRequestData = {
  namePattern: string
  plantId: string
}

const getPomUsersImpl: RequestFunc<GetPomUsersRequestData, UserDto[]> = async ({
  namePattern,
  plantId
}) => {
  const {data} = await dfApi.get<UserDto[]>(`/pom-questionnaires/${plantId}/pom-user-suggestions`, {
    params: {
      namePattern
    }
  })
  return sortBy(
    data.map(({userId, name}) => ({
      userId,
      name: name ?? ''
    })),
    ['name']
  )
}

export const getPomUsers = loggingDecorator(getPomUsersImpl)

const getUserImpl: RequestFunc<{id: string}, UserDto | undefined> = async ({id}) => {
  const {data} = await dfApi.get<User>(`/users/${id}`)
  return data
    ? {
        userId: data.id,
        name: data.name ?? ''
      }
    : undefined
}

export const getUser = loggingDecorator(getUserImpl)
