import {QuestionDifficulty} from '@hconnect/common/types'

import {ActionItem, PaginatedList} from '../types'

const QuestionDifficultyIdTag: Record<QuestionDifficulty, string> = {
  [QuestionDifficulty.Basic]: '-B-',
  [QuestionDifficulty.Advanced]: '-A-',
  [QuestionDifficulty.Excellence]: '-E-'
}

export const getQuestionDifficulty = (id: string): QuestionDifficulty => {
  if (id.includes(QuestionDifficultyIdTag.basic)) {
    return QuestionDifficulty.Basic
  } else if (id.includes(QuestionDifficultyIdTag.advanced)) {
    return QuestionDifficulty.Advanced
  } else if (id.includes(QuestionDifficultyIdTag.excellence)) {
    return QuestionDifficulty.Excellence
  }
  throw new Error(`Invalid ID format: ${id}`)
}

export const getFilteredData = (
  questionCategories?: QuestionDifficulty[],
  filteredActions?: PaginatedList<ActionItem>
): PaginatedList<ActionItem> | undefined => {
  const dataList =
    (questionCategories?.length
      ? filteredActions?.list.filter((item) => {
          if (item.questionId) {
            const questionDifficulty = getQuestionDifficulty(item.questionId)
            return questionCategories?.includes(questionDifficulty)
          }
        })
      : filteredActions?.list) || []
  return {
    ...filteredActions,
    list: dataList,
    total: filteredActions?.total || 0,
    page: filteredActions?.page || 0
  }
}
