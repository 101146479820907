import {CardBox, TextEllipsis} from '@hconnect/uikit/src/lib2'
import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton} from '@mui/material'
import React from 'react'

type SideCardContainerProps = {
  onClose?: () => void
  title?: string
  actions?: React.ReactNode
  children?: React.ReactNode
}

export const SideCardContainer = ({onClose, title, actions, children}: SideCardContainerProps) => {
  return (
    <CardBox
      data-test-id="action-page-side-card"
      sx={{
        maxHeight: '70vh',
        overflowY: 'auto',
        position: 'sticky',
        top: 80,
        p: {xs: 0, md: 0},
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box display="flex" justifyContent="space-between" p={3}>
        {title && <TextEllipsis variant="h3" title={title} />}

        {(actions || onClose) && (
          <Box mt={-1} mr={-1} ml={1} display="flex">
            {actions}
            {onClose && (
              <IconButton
                sx={{p: 1}}
                onClick={onClose}
                data-test-id={'action-page-side-card-close-button'}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      <Box px={3} pb={3} overflow="auto">
        {children}
      </Box>
    </CardBox>
  )
}
