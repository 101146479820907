import {QuestionDifficulty} from '@hconnect/common/types'
import {completionScoreBarColor} from '@hconnect/common/utils'
import {Column, DataTable, GaugeBar} from '@hconnect/uikit/src/lib2'
import {ChevronRight} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Category} from '../../types'
import {
  MIDDLE_PROGRESS_COLUMN_PADDING,
  SIDE_PROGRESS_COLUMN_PADDING
} from '../plantsOverview/plantTableUtils'

type CategoryTableProps = {
  categories: Category[]
  onRowClick: (id: string) => void
}

export const CategoryTable: FC<CategoryTableProps> = ({categories, onRowClick}) => {
  const {t} = useTranslation()

  const columns: Column<Category>[] = [
    {
      key: 'basic',
      label: t('headings.basic'),
      columnSx: {
        pr: {xs: SIDE_PROGRESS_COLUMN_PADDING, sm: SIDE_PROGRESS_COLUMN_PADDING},
        pl: {xs: 1.5, sm: 1.5}
      },
      customTemplate: ({difficultySummaries, id, name}) => (
        <GaugeBar
          value={difficultySummaries.basic.answeredPositively}
          maxValue={difficultySummaries.basic.questionCount}
          color={completionScoreBarColor(difficultySummaries, QuestionDifficulty.Basic)}
          minLabelWidth={56}
          barHeight={8}
          headerTemplate={
            <Typography variant="body1">
              {name}
              <Typography component="span" sx={{ml: 1}} variant="caption">
                {id}
              </Typography>
            </Typography>
          }
          customSubtitle={
            <Typography variant="subtitle2">
              {difficultySummaries.basic.answeredPositively}/
              {difficultySummaries.basic.questionCount}
            </Typography>
          }
          data-test-id={`section-${id}-basic`}
        />
      )
    },
    {
      key: 'advanced',
      label: t('headings.advanced'),
      columnSx: {px: {xs: MIDDLE_PROGRESS_COLUMN_PADDING, sm: MIDDLE_PROGRESS_COLUMN_PADDING}},
      customTemplate: ({difficultySummaries, id}) => (
        <GaugeBar
          value={difficultySummaries.advanced.answeredPositively}
          maxValue={difficultySummaries.advanced.questionCount}
          color={completionScoreBarColor(difficultySummaries, QuestionDifficulty.Advanced)}
          minLabelWidth={56}
          barHeight={8}
          customSubtitle={
            <Typography variant="caption">
              {difficultySummaries.advanced.answeredPositively}/
              {difficultySummaries.advanced.questionCount}
            </Typography>
          }
          data-test-id={`section-${id}-advanced`}
        />
      )
    },
    {
      key: 'excellence',
      label: t('headings.excellence'),
      columnSx: {
        pl: {xs: SIDE_PROGRESS_COLUMN_PADDING, sm: SIDE_PROGRESS_COLUMN_PADDING},
        pr: {xs: 1.5, sm: 1.5}
      },
      customTemplate: ({difficultySummaries, id}) => (
        <GaugeBar
          value={difficultySummaries.excellence.answeredPositively}
          maxValue={difficultySummaries.excellence.questionCount}
          color={completionScoreBarColor(difficultySummaries, QuestionDifficulty.Excellence)}
          minLabelWidth={56}
          barHeight={8}
          headerTemplate={
            <Box display="flex" mt={0.5} justifyContent="flex-end">
              <ChevronRight sx={{fontSize: 16, mb: 0.25}} />
            </Box>
          }
          customSubtitle={
            <Typography variant="caption">
              {difficultySummaries.excellence.answeredPositively}/
              {difficultySummaries.excellence.questionCount}
            </Typography>
          }
          data-test-id={`section-${id}-excellence`}
        />
      )
    }
  ]

  return (
    <Box mx={1.5}>
      <DataTable<Category>
        columns={columns}
        data={categories}
        onRowClick={(event, item) => onRowClick(item.id)}
        rowSx={() => ({
          cursor: 'pointer',
          '& .MuiTableCell-root': {
            py: 1.5,
            verticalAlign: 'bottom'
          }
        })}
      />
    </Box>
  )
}
