import {AppInsightsAdapter} from '@hconnect/common/logging'
import React, {Suspense} from 'react'
import {createRoot} from 'react-dom/client'

import {App} from './App'
import {initLocalization} from './localization'

initLocalization()
AppInsightsAdapter.init()
// Tracking information and logs will be sent to azure
AppInsightsAdapter.allowTracking()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as HTMLElement)

root.render(
  <Suspense fallback={<div />}>
    <App />
  </Suspense>
)
