import {parseIsoDateStringToDate} from '@hconnect/common/dates/ISO8601Helpers'
import {QuestionDifficulty} from '@hconnect/common/types'
import type {SortDirection} from '@mui/material'
import {compact, isString, lowerFirst} from 'lodash'

import {
  ActionItem,
  CreateActionItem,
  RequestFunc,
  FilteredActionsParams,
  PaginatedList,
  ParentSection,
  ParentTopic
} from '../../types'
import {ActionStatus} from '../../types/backend.types'
import {logger} from '../logger'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'
import {
  ActionItemDto,
  CreateActionItemDto,
  EditActionItemDto,
  ParentSectionDto,
  ParentTopicDto
} from './types'

interface Paging {
  offset: number
  limit: number
}

interface PagedContainerMetadata {
  paging?: Paging
  total: number
}

interface FilteredActionsResponse extends PagedContainerMetadata {
  items: ActionItemDto[]
}

enum ActionSortingOptions {
  Title = 1,
  Source = 2,
  Section = 3,
  Topic = 4,
  Assignee = 5,
  DueDate = 6,
  Status = 7
}

type SortingOrderParam = 'ascending' | 'descending'

export const parseId = (id: number | string) => String(id)

const convertParentSectionDto = (dto?: ParentSectionDto): ParentSection | undefined =>
  dto ? {id: dto.sectionId, name: dto.name} : undefined

const convertParentTopic = (dto?: ParentTopicDto): ParentTopic | undefined =>
  dto
    ? {
        id: dto.topicId,
        name: dto.name
      }
    : undefined

export const convertActionItemDto = (dto: ActionItemDto): ActionItem => ({
  id: parseId(dto.actionId),
  title: dto.title,
  description: dto.description,
  plantId: dto.plantId,
  questionId: dto.questionId,
  concurrencyStamp: dto.concurrencyStamp,
  creator: dto.creator,
  createdAt: parseIsoDateStringToDate(dto.createdAt),
  updatedAt: parseIsoDateStringToDate(dto.updatedAt),
  source: dto.source,
  status: dto.status,
  shifthandoverTaskId: dto.shifthandoverTaskId,
  assignee: dto.assignee,
  dueDate: parseIsoDateStringToDate(dto.dueDate),
  attachments: dto.attachments ?? [],
  section: convertParentSectionDto(dto.parentTopic?.parentSection),
  topic: convertParentTopic(dto.parentTopic)
})

export const convertCreateActionItemToDto = (
  actionItem: CreateActionItem
): CreateActionItemDto => ({
  title: actionItem.title,
  description: actionItem.description,
  source: actionItem.source,
  status: actionItem.status,
  questionId: actionItem.questionId,
  assigneeId: actionItem.assignee?.userId,
  dueDate: actionItem.dueDate.toISOString(),
  lockQuestionId: Boolean(actionItem.questionId)
})

export const convertActionItemToEditDto = (actionItem: ActionItem): EditActionItemDto => ({
  title: actionItem.title,
  description: actionItem.description,
  source: actionItem.source,
  status: actionItem.status,
  questionId: actionItem.questionId || undefined,
  assigneeId: actionItem.assignee?.userId,
  dueDate: actionItem.dueDate.toISOString(),
  concurrencyStamp: actionItem.concurrencyStamp
})

const createActionImpl: RequestFunc<
  {plantId: string; actionItem: CreateActionItem},
  ActionItem
> = async ({plantId, actionItem}) => {
  const {data} = await dfApi.post<ActionItemDto>(
    `/pom-questionnaires/${plantId}/actions`,
    convertCreateActionItemToDto(actionItem)
  )
  return convertActionItemDto(data)
}
export const createAction = loggingDecorator(createActionImpl)

const getActionsBySectionIdImpl: RequestFunc<
  {plantId: string; sectionId: string},
  ActionItem[]
> = async ({plantId, sectionId}) => {
  const {data} = await dfApi.get<ActionItemDto[]>(
    `/pom-questionnaires/${plantId}/sections/${sectionId}/actions`
  )
  return data.map(convertActionItemDto)
}
export const getActionsBySectionId = loggingDecorator(getActionsBySectionIdImpl)

const getActionsByTopicIdImpl: RequestFunc<
  {plantId: string; topicId: string},
  ActionItem[]
> = async ({plantId, topicId}) => {
  const {data} = await dfApi.get<ActionItemDto[]>(
    `/pom-questionnaires/${plantId}/topics/${topicId}/actions`
  )
  return data.map(convertActionItemDto)
}
export const getActionsByTopicId = loggingDecorator(getActionsByTopicIdImpl)

const getActionsByQuestionIdImpl: RequestFunc<
  {plantId: string; questionId: string},
  ActionItem[]
> = async ({plantId, questionId}) => {
  const {data} = await dfApi.get<ActionItemDto[]>(
    `/pom-questionnaires/${plantId}/questions/${questionId}/actions`
  )
  return data.map(convertActionItemDto)
}
export const getActionsByQuestionId = loggingDecorator(getActionsByQuestionIdImpl)

const getActionsByPlantIdImpl: RequestFunc<string, ActionItem[]> = async (plantId) => {
  const {data} = await dfApi.get<ActionItemDto[]>(`/pom-questionnaires/${plantId}/actions`)
  return data.map(convertActionItemDto)
}
export const getActionsByPlantId = loggingDecorator(getActionsByPlantIdImpl)

const deleteActionImpl: RequestFunc<{plantId: string; actionId: string}, void> = async ({
  plantId,
  actionId
}) => {
  await dfApi.delete(`/pom-questionnaires/${plantId}/actions/${actionId}`)
}
export const deleteAction = loggingDecorator(deleteActionImpl)

const updateActionImpl: RequestFunc<
  {plantId: string; actionItem: ActionItem},
  ActionItem
> = async ({plantId, actionItem}) => {
  const {data} = await dfApi.put<ActionItemDto>(
    `/pom-questionnaires/${plantId}/actions/${actionItem.id}`,
    convertActionItemToEditDto(actionItem)
  )
  return convertActionItemDto(data)
}
export const updateAction = loggingDecorator(updateActionImpl)

const getOrderParam = (order: SortDirection): string => {
  if (!order) {
    return ''
  }
  const param: SortingOrderParam = order === 'asc' ? 'ascending' : 'descending'
  return `order=${param}`
}

const ALLOWED_FILTER_KEYS = Object.values(ActionSortingOptions).filter(isString).map(lowerFirst)

const getQuestionCategories = (questionCategories?: QuestionDifficulty[]) =>
  questionCategories?.map((category) => `questionCategories=${category}`) ?? []

export const actionFilterToUrlParams = ({
  page,
  rowsPerPage,
  order = false,
  sortByKey,
  title,
  sources,
  statuses,
  questionIds,
  sectionIds,
  topicIds,
  assignees,
  dueDateRange,
  questionCategories
}: FilteredActionsParams): string => {
  // check that sortByKey is valid
  const sortKeyValid = !sortByKey || ALLOWED_FILTER_KEYS.includes(sortByKey)
  if (!sortKeyValid) {
    logger.warning(`Sort key ${sortByKey} is not supported!`)
  }

  const assigneeParams = assignees?.map((id) => `assignees=${id}`) ?? []
  const sourceParams = sources?.map((source) => `sources=${source}`) ?? []
  const statusParams = statuses?.map((status) => `statuses=${status}`) ?? []
  const questionCategoryParams = getQuestionCategories(questionCategories)
  const sectionIdParams = sectionIds?.map((id) => `sectionIds=${id}`) ?? []
  const topicIdParams = topicIds?.map((id) => `topicIds=${id}`) ?? []
  const questionIdParams = questionIds?.map((id) => `questionIds=${id}`) ?? []

  return compact([
    `offset=${page * rowsPerPage}`,
    `limit=${rowsPerPage}`,
    sortKeyValid && sortByKey ? `sortBy=${sortByKey}` : '',
    sortKeyValid ? getOrderParam(order) : '',
    title ? `title=${title}` : '',
    ...assigneeParams,
    ...sourceParams,
    ...statusParams,
    ...questionCategoryParams,
    ...sectionIdParams,
    ...topicIdParams,
    ...questionIdParams,
    dueDateRange?.from ? `dueDateStart=${dueDateRange.from.toJSON()}` : '',
    dueDateRange?.to ? `dueDateEnd=${dueDateRange.to.toJSON()}` : ''
  ]).join('&')
}

const getFilteredActionsImpl: RequestFunc<
  FilteredActionsParams,
  PaginatedList<ActionItem>
> = async (parameters) => {
  const params = actionFilterToUrlParams(parameters)

  const {data} = await dfApi.get<FilteredActionsResponse>(
    `/pom-questionnaires/${parameters.plantId}/filtered-actions?${params}`
  )
  return {
    list: data.items.map(convertActionItemDto),
    total: data.total,
    page: (data.paging?.offset ?? 0) * parameters.rowsPerPage
  }
}

export const getFilteredActions = loggingDecorator(getFilteredActionsImpl)

const allRelatedActionsDone = async ({
  questionId,
  plantId
}: {
  questionId: string
  plantId: string
}) => {
  try {
    if (questionId) {
      const otherActions = await getActionsByQuestionId({plantId, questionId})
      return otherActions.every(({status}: ActionItem) => status === ActionStatus.Done)
    }
  } catch (e) {
    return false
  }
}

export const allRelatedActionsDoneForQuestionOfAction = async ({plantId, questionId}: ActionItem) =>
  allRelatedActionsDone({questionId, plantId})

const downloadPlantActionsImpl: RequestFunc<string, Blob> = async (plantId) => {
  const {data} = await dfApi.get<Blob>(`/pom-questionnaires/${plantId}/actions/excel-sheet`, {
    responseType: 'blob'
  })
  return data
}

export const downloadPlantActions = loggingDecorator(downloadPlantActionsImpl)
