import {AnswerStatus} from '@hconnect/common/types'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {QuestionItem} from '../../types'

import {AnswerDropDownButton} from './AnswerDropDownButton'
import {QuestionsText} from './QuestionsText'

type QuestionItemsProps = {
  setQuestionId: (id: string) => void
  onAnswerSelected: (id: string, answer: AnswerStatus) => void
  questionItems: QuestionItem[]
}

export const QuestionTable: FC<QuestionItemsProps> = ({
  questionItems,
  setQuestionId,
  onAnswerSelected
}) => {
  const {t} = useTranslation()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('questionnaire.tableHeader.question')}</TableCell>
            <TableCell align="right">{t('questionnaire.tableHeader.answer')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionItems.map(({id, questions, answer}) => (
            <TableRow key={id}>
              <TableCell
                sx={(theme) => ({
                  '&:hover': {
                    cursor: 'pointer',
                    color: theme.palette.primary.main
                  }
                })}
                onClick={() => setQuestionId(id)}
              >
                <QuestionsText questions={questions} />
              </TableCell>
              <TableCell align="right">
                <AnswerDropDownButton
                  id={id}
                  selected={answer}
                  onSelected={(answer) => onAnswerSelected(id, answer)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
