import {RequestFunc} from '../../types'
import {Question} from '../../types/backend.types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'

const getPlantQuestionsImpl: RequestFunc<string, Question[]> = async (plantId) => {
  const {data} = await dfApi.get<Question[]>(`/pom-questionnaires/${plantId}/questions`)
  return data
}

export const getPlantQuestions = loggingDecorator(getPlantQuestionsImpl)
