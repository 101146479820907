import type {GuidedTour} from '@hconnect/uikit/src/lib2/components/guidedTour/types'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

const getStepTranslationKey = (index: number, key: 'title' | 'description') =>
  `guidedTours.introductionTour.steps.${index}.${key}`

export const usePOMTours = (): Record<string, GuidedTour> => {
  const {t} = useTranslation()

  return useMemo(
    (): Record<string, GuidedTour> => ({
      recentChangesTour: {
        title: t('guidedTours.introductionTour.title'),
        description: t('guidedTours.introductionTour.description'),
        image: <></>,
        steps: [
          {
            target: '#notifications-icon',
            title: t(getStepTranslationKey(0, 'title')),
            content: t(getStepTranslationKey(0, 'description')),
            placement: 'bottom'
          }
        ]
      }
    }),
    [t]
  )
}
