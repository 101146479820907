import {WhiteList} from '@hconnect/common/hproduce'

import {PomUser} from './domain.types'

export interface AuthContext {
  whiteList?: WhiteList
  user?: PomUser
  login: () => void
  logout: () => void
  isLoggedIn: boolean
  isLoading: boolean
}
