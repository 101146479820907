import {useQuery, UseQueryResult} from 'react-query'

import {QueryKeyName, getActionsByPlantId} from '../../common/backend'
import {ActionItem} from '../../types'
import {usePlantId} from '../urlParameters/usePlantId'

export const usePlantActions = (): UseQueryResult<ActionItem[]> => {
  const plantId = usePlantId()
  return useQuery([QueryKeyName.GetActionItems, plantId], () => getActionsByPlantId(plantId))
}
