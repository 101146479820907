import {QuestionDifficulty} from '@hconnect/common/types'
import {QUESTION_DIFFICULTIES} from '@hconnect/common/utils'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Star} from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done'
import {ToggleButton, Typography, ToggleButtonGroup, Box, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface QuestionDifficultyButtonGroupProps {
  difficulty: QuestionDifficulty
  groupsCompleted: QuestionDifficulty[]
  onDifficultyChanged: (difficulty: QuestionDifficulty) => void
}

const DifficultyButtonCaption: React.FC<{label: string; starCount: number; completed: boolean}> = ({
  label,
  completed,
  starCount
}) => (
  <Stack direction="column" minWidth={{xs: 0, sm: 120}}>
    <Typography variant="button" gutterBottom>
      {label}
    </Typography>
    <Box>
      {completed ? (
        <DoneIcon sx={{fontSize: '0.75rem'}} />
      ) : (
        Array.from({length: starCount}, (_, i) => <Star key={i} sx={{fontSize: '0.75rem'}} />)
      )}
    </Box>
  </Stack>
)

export const QuestionDifficultyButtonGroup: React.FC<QuestionDifficultyButtonGroupProps> = ({
  difficulty,
  groupsCompleted,
  onDifficultyChanged
}) => {
  const {t} = useTranslation()
  const onValueChanged = (
    event: React.MouseEvent<HTMLElement>,
    questionDifficulty: QuestionDifficulty | null
  ) => {
    if (questionDifficulty) {
      onDifficultyChanged(questionDifficulty)
    }
  }

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      sx={{mb: 3, mx: 1.5}}
      value={difficulty}
      onChange={onValueChanged}
      aria-label={t('questionnaire.difficulty.ariaDifficultySelect')}
    >
      {QUESTION_DIFFICULTIES.map((questionDifficulty, index) => {
        const completed = groupsCompleted.includes(questionDifficulty)
        const buttonStyle = completed
          ? {
              color: 'success.light',
              backgroundColor: customThemeConstants().palette.successBackgroundColor
            }
          : {}
        return (
          <ToggleButton
            key={questionDifficulty}
            value={questionDifficulty}
            data-test-id={`question-difficulty-btn-${questionDifficulty}`}
            sx={buttonStyle}
          >
            <DifficultyButtonCaption
              label={t(`questionDifficulty.${questionDifficulty}`)}
              completed={completed}
              starCount={index + 1}
            />
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
