import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useQueryClient, useMutation, UseMutationResult} from 'react-query'

import {addAttachmentsToQuestion} from '../../common/backend'
import {Attachment} from '../../types/backend.types'

interface UploadFilesArgs {
  plantId: string
  questionId: string
  files: File[]
}

export const useAddQuestionAttachments = (): UseMutationResult<
  Attachment[],
  unknown,
  UploadFilesArgs
> => {
  const {t} = useTranslation()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    (args: UploadFilesArgs) => {
      return addAttachmentsToQuestion(args)
    },
    {
      onSuccess: (data) => {
        void client.invalidateQueries()
        enqueueSnackbar(t('attachments.uploaded.success', {count: data.length}), {
          variant: 'success'
        })
      },
      onError: () => {
        enqueueSnackbar(t('attachments.uploaded.error'), {variant: 'error'})
      }
    }
  )
}
