import {useQuery, UseQueryResult} from 'react-query'

import {QueryKeyName, getActionsByQuestionId} from '../../common/backend'
import {ActionItem} from '../../types'
import {usePlantId} from '../urlParameters/usePlantId'

export const useQuestionActions = (questionId: string): UseQueryResult<ActionItem[]> => {
  const plantId = usePlantId()

  return useQuery([QueryKeyName.GetActionItems, plantId, questionId], async () => {
    return await getActionsByQuestionId({plantId, questionId})
  })
}
