import {AnswerStatus} from '@hconnect/common/types'
import {formatTimeZoneDate} from '@hconnect/uikit'
import {MarkdownText} from '@hconnect/uikit/src/lib2'
import {Avatar, Box, Typography} from '@mui/material'
import i18next from 'i18next'
import React, {FC, ReactNode, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getInitials} from '../../common/ownerDetails'
import {useEditAction} from '../../hooks/api/useEditAction'
import {useTimeZone} from '../../hooks/useTimeZone'
import {ActionItem} from '../../types'
import {ActionStatusType} from '../../types/backend.types'
import {StatusField} from '../actionDialog/StatusField'
import {MoveQuestionToDone} from '../actionsCompleted/MoveQuestionToDone'

import {ActionConnectedToSectionLink} from './ActionConnectedToSectionLink'

type ActionDetailsSectionProps = {
  title: string
  value?: string | ReactNode
  mb?: number
}

type ActionsDetailsProps = {
  action: ActionItem
  onChange: (action: ActionItem) => void
  questionAnswer?: AnswerStatus
}

const ActionDetailsSection: React.FC<ActionDetailsSectionProps> = ({title, value, mb = 3}) => (
  <Box mb={mb}>
    <Typography variant="caption">{title}</Typography>
    <Typography component={'div'} sx={() => ({fontWeight: 500})}>
      {value || '-'}
    </Typography>
  </Box>
)

export const ActionsDetails: FC<ActionsDetailsProps> = ({action, onChange, questionAnswer}) => {
  const timeZone = useTimeZone()
  const {t} = useTranslation()

  const [showMoveQuesDialog, setShowMoveQuesDialog] = useState<boolean>(false)

  const {mutate: editAction} = useEditAction(({item, showMoveQuestToDone}) => {
    onChange(item)
    showMoveQuestToDone && setShowMoveQuesDialog(true)
  })

  const handleStatusChange = useCallback(
    (status: ActionStatusType) => {
      if (status !== action.status) {
        editAction({actionItem: {...action, status}, questionAnswer})
      }
    },
    [action, editAction, questionAnswer]
  )

  return (
    <>
      <Box mb={3} sx={{maxWidth: 150}}>
        <StatusField value={action.status} onChange={handleStatusChange} />
      </Box>

      <Box>{action.description && <MarkdownText>{action.description}</MarkdownText>}</Box>

      <ActionDetailsSection
        title={t('actionDetails.assignee')}
        value={
          <Box display="flex" alignItems="center">
            {action.assignee && (
              <Avatar sx={{mr: 1, width: 20, height: 20, fontSize: 11}}>
                {getInitials(action.assignee?.name)}
              </Avatar>
            )}
            {action.assignee ? action.assignee.name : '-'}
          </Box>
        }
      />

      <ActionDetailsSection
        title={t('actionDetails.dueDate')}
        value={formatTimeZoneDate(action.dueDate, timeZone, 'll', i18next.language)}
      />

      <ActionDetailsSection
        title={t('actionDetails.source')}
        mb={action.questionId ? 3 : 0}
        value={t(`actionSource.${action.source}`, '-')}
      />

      {action.questionId && action.section?.id && <ActionConnectedToSectionLink action={action} />}

      {showMoveQuesDialog && (
        <MoveQuestionToDone actionItem={action} onClose={() => setShowMoveQuesDialog(false)} />
      )}
    </>
  )
}
