import {formatTimeZoneDate} from '@hconnect/uikit'
import {customThemeConstants, mergeSx} from '@hconnect/uikit/src/lib2'
import {TableCell, Typography, TableRow, SxProps, Theme} from '@mui/material'
import i18next from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTimeZone} from '../../hooks/useTimeZone'
import {ActionItem, ActionItemKey} from '../../types'

interface ActionRowProps {
  action: ActionItem
  isOverdue: boolean
  visibleColumns: ActionItemKey[]
  onClick?: (action: ActionItem) => void
}

const columnKeySxMap: Partial<Record<ActionItemKey, SxProps<Theme>>> = {
  title: (theme) => ({
    fontWeight: theme.typography.fontWeightMedium
  }),
  dueDate: {height: 56, py: 0.5}
}

const cellSx = (columnKey: ActionItemKey): SxProps<Theme> => columnKeySxMap[columnKey] ?? {}

const CellContent: React.FC<{
  columnKey: ActionItemKey
  action: ActionItem
  isOverdue: boolean
}> = ({columnKey, action, isOverdue}) => {
  const {t} = useTranslation()
  const timeZone = useTimeZone()

  if (columnKey === 'assignee') {
    return <>{action.assignee?.name}</>
  }
  if (columnKey === 'dueDate') {
    return (
      <>
        {formatTimeZoneDate(action.dueDate, timeZone, 'll', i18next.language)}
        {isOverdue && (
          <Typography component="span" variant="caption" sx={{opacity: 1}} color="error">
            <br />
            {t('actionsTable.overdue')}
          </Typography>
        )}
      </>
    )
  }
  if (columnKey === 'status') {
    return <>{t(`actions.${action.status}`)}</>
  }
  if (columnKey === 'source') {
    return <>{t(`actionSource.${action.source}`)}</>
  }
  if (columnKey === 'topic') {
    return <>{action.topic?.id}</>
  }
  if (columnKey === 'section') {
    return <>{action.section?.id}</>
  }
  return <>{action[columnKey]}</>
}

export const ActionRow: React.FC<ActionRowProps> = ({
  action,
  isOverdue,
  visibleColumns,
  onClick
}) => {
  return (
    <TableRow
      data-test-id={`actions-table-row-${action.id}`}
      key={action.id}
      onClick={() => onClick?.(action)}
      sx={[
        isOverdue && {
          backgroundColor: customThemeConstants().palette.errorBackgroundColor
        },
        !!onClick && {
          cursor: 'pointer'
        }
      ]}
    >
      {visibleColumns.map((columnKey, index) => (
        <TableCell
          key={columnKey}
          sx={mergeSx(
            (theme) =>
              index === 0 && isOverdue
                ? {
                    borderLeft: 4,
                    pl: '12px',
                    borderLeftColor: theme.palette.error.main
                  }
                : {},
            cellSx(columnKey)
          )}
        >
          <CellContent action={action} isOverdue={isOverdue} columnKey={columnKey} />
        </TableCell>
      ))}
    </TableRow>
  )
}
