import {RemoveCircle, InsertDriveFile} from '@mui/icons-material'
import {Box, IconButton, Typography, Theme, Link} from '@mui/material'
import React from 'react'

import {useAttachmentFiles} from '../../hooks/api/useAttachmentFiles'
import {Attachment} from '../../types/backend.types'

import {ImageFilePreview} from './ImageFilePreview'

interface FilePreviewProps {
  attachment: Attachment
  width?: number | string
  height?: number | string
  onRemoveClicked?: (file: Attachment) => void
}

const IconFilePreview: React.FC<Pick<FilePreviewProps, 'width' | 'height' | 'attachment'>> = ({
  width,
  height,
  attachment
}) => (
  <Box
    width={width}
    height={height}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
    flexDirection={'column'}
  >
    <Box component={'span'} color={'primary.main'}>
      <InsertDriveFile />
    </Box>
    <Typography
      variant="caption"
      sx={{overflowWrap: 'break-word', width: '100%', textAlign: 'center'}}
    >
      {attachment.fileName}
    </Typography>
  </Box>
)

export const FilePreview: React.FC<FilePreviewProps> = ({
  attachment,
  height,
  width,
  onRemoveClicked
}) => {
  const {mediaType, fileName} = attachment
  const {fileHref: downloadHref, previewFileHref: previewHref} = useAttachmentFiles(attachment)
  const hasImageUrl = mediaType.startsWith('image/')

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block'
      }}
    >
      <Box
        border={1}
        sx={(theme) => ({
          borderColor: theme.palette.grey[300],
          borderRadius: 1,
          overflow: 'hidden',
          '&:hover': {
            borderColor: theme.palette.primary.main
          }
        })}
      >
        {hasImageUrl ? (
          <ImageFilePreview
            width={width}
            height={height}
            filename={fileName}
            downloadHref={downloadHref}
            previewHref={previewHref}
          />
        ) : (
          <Link
            href={downloadHref}
            download={fileName}
            underline={'none'}
            rel="noreferrer noopener"
            target="_blank"
          >
            <IconFilePreview width={width} height={height} attachment={attachment} />
          </Link>
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '-20px',
          right: '-20px'
        }}
      >
        <IconButton
          color={'primary'}
          onClick={() => onRemoveClicked?.(attachment)}
          data-test-id={`file-preview-delete-btn-${attachment.fileName}`}
        >
          <Box
            display={'flex'}
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.background.paper,
              borderRadius: 2
            })}
          >
            <RemoveCircle />
          </Box>
        </IconButton>
      </Box>
    </Box>
  )
}
