import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useQueryClient, useMutation} from 'react-query'

import {QueryKeyName, deleteComment} from '../../common/backend'
import {PomComment} from '../../types'
import {usePlantId} from '../urlParameters/usePlantId'

export const useDeleteComment = () => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation((comment: PomComment) => deleteComment({plantId, comment}), {
    onSuccess: () => {
      void client.invalidateQueries(QueryKeyName.GetComments)
    },
    onError: () => {
      enqueueSnackbar(t('comments.deleted.error'), {variant: 'error'})
    }
  })
}
