import {TextField} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {noop} from 'lodash'
import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

type DueDateFieldProps = {
  date?: Date
  onChange?: (date?: Date) => void
  error: boolean
}

export const DueDateField: React.FC<DueDateFieldProps> = ({date, onChange = noop, error}) => {
  const {t} = useTranslation()
  const [dateValue, setDateValue] = useState(date ?? null)

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={t('actionDialog.dueDate')}
        value={dateValue}
        inputFormat={t('dateTime.dateFormat', 'MM/dd/yyyy')}
        onChange={(val) => {
          setDateValue(val)
        }}
        onAccept={(value) => {
          onChange(value ?? undefined)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            name="dueDate"
            variant="filled"
            error={error}
            data-test-id="action-due-date"
            onBlur={() => {
              const correctedValue =
                dateValue && moment(dateValue).isValid() ? dateValue : undefined
              setDateValue(correctedValue ?? null)
              onChange(correctedValue)
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
