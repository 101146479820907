import {localTimeToTimeZoneTime, timeZoneTimeToLocalTime} from '@hconnect/common/dates/timeZone'
import {Box, Typography, useTheme} from '@mui/material'
import TextField from '@mui/material/TextField'
import SimpleMDE from 'easymde'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import SimpleMdeReact from 'react-simplemde-editor'

import {dueDate} from '../../common/domain/date'
import {removeHTMLTags} from '../../common/format'
import {MAX_DESCRIPTION_CHARACTER_LIMIT} from '../../common/validator/actionValidator'
import {ItemValidationResult, ValidationError} from '../../common/validator/validator.types'
import {useTimeZone} from '../../hooks/useTimeZone'
import {ActionItem, CreateActionItem} from '../../types'
import {DueDateField} from '../actionDialog/DueDateField'
import {SourceField} from '../actionDialog/SourceField'
import {StatusField} from '../actionDialog/StatusField'
import {ResponsiblePersonField} from '../responsiblePerson/ResponsiblePersonField'

import {ActionConnectedToSection} from './ActionConnectedToSection'
import 'easymde/dist/easymde.min.css'

type ActionFormProps<T> = {
  formValues: Partial<T>
  validationErrors: ItemValidationResult<Partial<ActionItem>>
  handleInputChange: (key: keyof T, value) => void
  actionItem: Partial<T>
  enableSpellChecking?: boolean
}

export const ActionForm = <T extends ActionItem | CreateActionItem>({
  formValues,
  validationErrors,
  handleInputChange,
  actionItem
}: ActionFormProps<T>) => {
  const theme = useTheme()
  const {t} = useTranslation()
  const timeZone = useTimeZone()

  const options = useMemo<SimpleMDE.Options>(() => {
    return {
      toolbar: ['bold', 'italic', 'strikethrough', '|', 'unordered-list', 'ordered-list'],
      status: false,
      minHeight: '5em',
      inputStyle: 'contenteditable',
      placeholder: `${t('actionDialog.description')} *`
    }
  }, [t])

  return (
    <>
      <Box my={2}>
        <TextField
          id="title"
          label={t('actionDialog.title')}
          variant="filled"
          fullWidth={true}
          value={formValues.title}
          required
          onChange={(e) => handleInputChange('title', e.target.value)}
          error={Boolean(validationErrors.title)}
          data-test-id="action-title"
        />
      </Box>

      <Box my={2} data-test-id="action-description">
        <Box
          component={SimpleMdeReact}
          id="description"
          value={formValues.description || ''}
          onChange={(next) => handleInputChange('description', next)}
          options={options}
          width="100%"
          sx={{
            backgroundColor: theme.palette.grey['100'],
            '.CodeMirror': {
              maxHeight: '320px',
              backgroundColor: theme.palette.grey['100']
            },
            '.CodeMirror-scroll': {
              maxHeight: '300px'
            },
            '.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word)':
              {
                background: 'unset'
              }
          }}
        />
        <Box display="flex" justifyContent="space-between">
          <Box>
            {validationErrors.description === ValidationError.maxSizeExceeded && (
              <Typography variant="caption" sx={{color: theme.palette.error.light}}>
                {t(validationErrors.description, {maxLength: MAX_DESCRIPTION_CHARACTER_LIMIT})}
              </Typography>
            )}
          </Box>
          <Typography pt={0.5} pr={0.5} variant="caption">
            {removeHTMLTags(formValues.description).length}/{MAX_DESCRIPTION_CHARACTER_LIMIT}
          </Typography>
        </Box>
      </Box>

      <Box my={2}>
        <ResponsiblePersonField
          error={Boolean(validationErrors.assignee)}
          value={formValues.assignee}
          onChange={(value) => handleInputChange('assignee', value)}
        />
      </Box>

      <Box my={2}>
        <DueDateField
          date={
            formValues.dueDate ? timeZoneTimeToLocalTime(formValues.dueDate, timeZone) : undefined
          }
          onChange={(value) => {
            // interpret the Date as local date of the plant's time zone
            const date = value
              ? dueDate(localTimeToTimeZoneTime(value, timeZone), timeZone)
              : undefined
            handleInputChange('dueDate', date)
          }}
          error={!!validationErrors.dueDate}
        />
      </Box>

      {actionItem.questionId ? (
        <ActionConnectedToSection value={actionItem.questionId} />
      ) : (
        <SourceField
          value={formValues.source}
          error={Boolean(validationErrors.source)}
          onChange={(value) => handleInputChange('source', value)}
        />
      )}

      <Box my={2}>
        <StatusField
          value={formValues.status}
          error={Boolean(validationErrors.status)}
          onChange={(value) => handleInputChange('status', value)}
        />
      </Box>
    </>
  )
}
