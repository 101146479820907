import {Shell} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {PomFooter} from '../components/PomFooter'
import {ShellHeader} from '../components/ShellHeader'

export const DefaultShell = ({children}: {children: React.ReactNode}) => (
  <Shell isResponsive boxed={false} header={<ShellHeader />} footer={<PomFooter />}>
    {children}
  </Shell>
)
