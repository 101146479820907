export enum ValidationError {
  // all possible validation error strings
  notEmpty = 'shouldBe.notEmpty',
  invalidDate = 'shouldBe.valid',
  maxSizeExceeded = 'shouldBe.shorter'
}

export type ValidationResult = ValidationError | undefined

export type ItemValidationResult<T> = Partial<Record<keyof T, ValidationResult>>

export type ActionValidator<T, K extends keyof T = keyof T> = (
  value: T[K],
  actionItem?: T
) => ValidationResult

export type ValidatorKeyMap<T, K extends keyof T = keyof T> = {
  [key in K]?: ActionValidator<T, key>
}
