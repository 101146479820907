import {UserDto} from '@hconnect/common/types'
import {CancelButton} from '@hconnect/uikit/src/lib2'
import {Check} from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import {LoadingButton} from '@mui/lab'
import {Avatar, Box, IconButton, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {getInitials} from '../../common/ownerDetails'
import {PathParameters} from '../../common/routing'
import {useAssignResponsible} from '../../hooks/api/useAssignResponsible'
import {ResponsiblePersonField} from '../responsiblePerson/ResponsiblePersonField'

interface ResponsiblePersonProps {
  person?: UserDto
}

const PersonView: React.FC<{name: string; onEditClicked: () => void}> = ({name, onEditClicked}) => {
  const initials = getInitials(name)
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        data-test-id={'responsible-person-view'}
      >
        <Avatar sx={{mr: 2}}>{initials}</Avatar>
        <Typography variant="subtitle1">{name}</Typography>
      </Box>
      <Box sx={{float: 'right'}}>
        <IconButton color="primary" sx={{mr: 2}} disabled>
          <MailOutlineIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={onEditClicked}
          data-test-id={'responsible-person-edit-btn'}
        >
          <EditIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

const PersonForm: React.FC<{
  onCancel: () => void
  onSave: () => void
  person?: UserDto
}> = ({onCancel, onSave, person}) => {
  const {t} = useTranslation()
  const [selected, setSelected] = useState(person)
  const {sectionId, topicId} = useParams<PathParameters>()
  const {mutate, isLoading: savingPerson} = useAssignResponsible(() => onSave())

  return (
    <Box>
      <Box mb={1.5}>
        <ResponsiblePersonField value={selected} onChange={(person) => setSelected(person)} />
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <CancelButton onClick={onCancel}>{t('button.cancel')}</CancelButton>
        <LoadingButton
          data-test-id={'responsible-person-save-btn'}
          variant="contained"
          startIcon={<Check />}
          sx={{ml: 2}}
          disabled={!selected}
          loading={savingPerson}
          onClick={() => {
            if (selected) {
              mutate({userId: selected.userId, sectionId, topicId})
            }
          }}
        >
          {t('button.save')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export const ResponsiblePerson: React.FC<ResponsiblePersonProps> = ({person}) => {
  const {t} = useTranslation()
  const [editing, setEditing] = useState(false)

  const onEditClicked = () => {
    setEditing(true)
  }

  const onClose = () => {
    setEditing(false)
  }

  const name: string = person?.name || t('responsiblePerson.unknownUser')

  return (
    <Box mt={3}>
      <Typography variant="h4" sx={{mb: 2}}>
        {t('responsiblePerson.responsible')}
      </Typography>
      {editing ? (
        <PersonForm onCancel={onClose} onSave={onClose} />
      ) : (
        <PersonView name={name} onEditClicked={onEditClicked} />
      )}
    </Box>
  )
}
