import {Box} from '@mui/material'
import React from 'react'

export const ActionsContainer = ({children}: {children?: React.ReactNode}) => {
  return (
    <Box
      data-test-id="actions-card"
      sx={(theme) => ({
        borderRadius: 1,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        pt: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        overflow: 'hidden'
      })}
    >
      {children}
    </Box>
  )
}
