import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Box, Skeleton} from '@mui/material'
import React, {useCallback} from 'react'

import {useActionId} from '../../hooks/urlParameters/useSearchParameter'
import {ActionItem} from '../../types'

import {ActionSideCard} from './ActionSideCard'
import {ActionsTable} from './ActionsTable'

interface ActionsCardProps {
  actions: ActionItem[]
}

export const ActionCardSkeleton: React.FC = () => (
  <Box display="flex" flexDirection="column" width="100%" height="100%">
    <CardTitle height={48}>
      <Skeleton height="100%" />
    </CardTitle>
    <Skeleton sx={{flex: 1}} variant="rectangular" />
  </Box>
)

export const ActionsCard: React.FC<ActionsCardProps> = ({actions}) => {
  const [actionId, setActionId] = useActionId()

  const onRowClick = useCallback(
    (action: ActionItem) => {
      setActionId(action.id)
    },
    [setActionId]
  )

  const selectedAction = actionId ? actions.find((item) => item?.id === actionId) : undefined
  const isTableShown = !selectedAction

  return (
    <Box
      data-test-id="actions-card"
      sx={(theme) => ({
        borderRadius: 1,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden'
      })}
    >
      {isTableShown && (
        <Box minHeight={290}>
          <ActionsTable actions={actions} onRowClick={onRowClick} />
        </Box>
      )}
      {selectedAction && (
        <Box display="flex" flexDirection="column">
          <ActionSideCard selectedAction={selectedAction} />
        </Box>
      )}
    </Box>
  )
}
