import {createHttpClient} from '@hconnect/apiclient'
import {
  authRequestProvider,
  backendSelector,
  loginFlow,
  loginStorage
} from '@hconnect/common/authentication/loginClasses'
import type {AxiosInstance} from 'axios'

export const dfApi: AxiosInstance = createHttpClient({
  backendSelector,
  authRequestProvider,
  loginStorage,
  loginFlow
})
