import React from 'react'

import {CategoryTable} from '../components/categoryTable'
import {Category} from '../types'

interface SectionsOverviewProps {
  sections: Category[]
  onRowClick: (id: string) => void
}

export const SectionsOverview: React.FC<SectionsOverviewProps> = ({onRowClick, sections}) => {
  return <CategoryTable categories={sections} onRowClick={onRowClick} />
}
