import {QuestionDifficulty, AnswerStatus} from '@hconnect/common/types'
import {ErrorMessage, Loader} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {UseQueryResult} from 'react-query'

import {
  completedDifficultyLevels,
  questionsToQuestionItemsMap
} from '../../common/domain/questionnaire'
import {useStringSearchParameter} from '../../hooks/urlParameters/useSearchParameter'
import {Question} from '../../types/backend.types'

import {QuestionDifficultyButtonGroup} from './QuestionDifficultyButtonGroup'
import {QuestionTable} from './questionTable'

interface QuestionnaireProps {
  questionList: UseQueryResult<Question[]>
  onAnswerSelected: (id: string, answer: AnswerStatus) => void
  onDifficultyChanged: (difficulty: QuestionDifficulty) => void
  difficulty: QuestionDifficulty
  boxSx?: SxProps<Theme>
}

export const Questionnaire: React.FC<QuestionnaireProps> = ({
  questionList,
  onAnswerSelected,
  onDifficultyChanged,
  difficulty,
  boxSx
}) => {
  const {t} = useTranslation()
  const [, setQuestionId] = useStringSearchParameter('selectedQuestionId')

  const {isLoading, error} = questionList

  const questions = useMemo(
    () => questionsToQuestionItemsMap(questionList?.data ?? []),
    [questionList]
  )

  const questionItems = questions[difficulty]

  const groupsCompleted: QuestionDifficulty[] = useMemo(
    () => completedDifficultyLevels(questionList.data ?? []),
    [questionList.data]
  )

  return (
    <Box sx={boxSx} data-test-id="questionnaire-container">
      <QuestionDifficultyButtonGroup
        difficulty={difficulty}
        groupsCompleted={groupsCompleted}
        onDifficultyChanged={onDifficultyChanged}
      />
      <Box minHeight={160}>
        {isLoading ? (
          <Loader pt={6} />
        ) : error ? (
          <Box pt={3}>
            <ErrorMessage>{t('errors.questionsNotFetched')}</ErrorMessage>
          </Box>
        ) : (
          <QuestionTable
            setQuestionId={setQuestionId}
            questionItems={questionItems}
            onAnswerSelected={onAnswerSelected}
          />
        )}
      </Box>
    </Box>
  )
}
