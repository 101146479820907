import {SectionDto} from '@hconnect/common/types'

import {Section, RequestFunc} from '../../types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'
import {convertScoring} from './scoring'
import {convertTopic} from './topics'

export const convertSection = (dto: SectionDto): Section => ({
  ...convertScoring(dto.scoring),
  id: dto.sectionId,
  name: dto.name,
  responsible: dto.responsible,
  topics: dto.topics.map((topic) => convertTopic(topic)),
  guidanceLink: dto.guidanceLink
})

const getSectionImpl: RequestFunc<{plantId: string; sectionId: string}, Section> = async ({
  plantId,
  sectionId
}) => {
  const {data} = await dfApi.get<SectionDto>(`/pom-questionnaires/${plantId}/sections/${sectionId}`)
  return convertSection(data)
}
export const getSection = loggingDecorator(getSectionImpl)

const assignSectionResponsibleImpl: RequestFunc<
  {plantId: string; sectionId: string; userId: string},
  void
> = async ({plantId, sectionId, userId}) => {
  await dfApi.post(`/pom-questionnaires/${plantId}/sections/${sectionId}/responsible`, {userId})
}
export const assignSectionResponsible = loggingDecorator(assignSectionResponsibleImpl)

const getSectionsImpl: RequestFunc<void, Section[]> = async () => {
  const {data} = await dfApi.get<SectionDto[]>('/pom-questionnaires/sections')
  return data.map((sec) => convertSection(sec))
}

export const getSections = loggingDecorator(getSectionsImpl)

const downloadSectionSubmissionsImpl: RequestFunc<
  {plantId: string; sectionId: string},
  Blob
> = async ({plantId, sectionId}) => {
  const {data} = await dfApi.get<Blob>(
    `/pom-questionnaires/${plantId}/sections/${sectionId}/submissions/excel-sheet`,
    {
      responseType: 'blob'
    }
  )
  return data
}

export const downloadSectionSubmissions = loggingDecorator(downloadSectionSubmissionsImpl)
