import type {DecodedToken, User} from '@hconnect/apiclient'
import {getPermissionsByUserId} from '@hconnect/apiclient'
import {loginFlow} from '@hconnect/common/authentication/loginClasses'
import {WhiteList, whiteListByPermission, Env} from '@hconnect/common/hproduce'
import type {AxiosError, AxiosInstance} from 'axios'

import type {PomUser} from '../../types'
import {logger} from '../logger'

import {dfApi} from './apiClient'

export const isUserLoggedIn = (user?: PomUser): boolean => {
  return Boolean(user?.userId)
}

export type PomInitData = {
  user: PomUser
  whiteList: WhiteList
}

const initialize = async (
  decodedToken: DecodedToken,
  dfApi: AxiosInstance
): Promise<PomInitData | undefined> => {
  const log = logger.context('loadUser')
  const {user_id: userId, email} = decodedToken
  if (!userId || !email) {
    log.warning(
      'Loading the user failed, because the token does not contain the user ID and email.'
    )
    return undefined
  }

  try {
    const response = await dfApi.get<User>(`/users/${userId}`)

    const pUser: User = response.data
    if (!pUser.name) {
      log.warning('Loading the user failed, because the name is empty.')
      return undefined
    }
    const permissions = await getPermissionsByUserId(dfApi)(userId)
    if (!permissions) {
      log.warning('Loading the user permissions failed, because the list is undefined.')
      return undefined
    }
    const whiteList = whiteListByPermission(process.env.REACT_APP_STAGE as Env, permissions)

    return {
      whiteList,
      user: {
        userId,
        email,
        name: pUser.name,
        roles: pUser.hasRoles ?? []
      }
    }
  } catch (e: unknown) {
    log.error(e as AxiosError)
    throw e
  }
}

export const tryInitialize = async (): Promise<PomInitData | undefined> => {
  const loginResponse = await loginFlow.getLoginState()
  if (!loginResponse.loggedIn) {
    return undefined
  }

  return initialize(loginResponse.decodedToken, dfApi)
}

export const startLoginProcess = () => loginFlow.startLoginProcess()

export const startLogoutProcess = () => loginFlow.startLogoutProcess()
