import {QuestionDifficulty} from '@hconnect/common/types'

import {RequestFunc} from '../../types'
import {Question} from '../../types/backend.types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'

const getQuestionsImpl: RequestFunc<
  {plantId: string; topicId: string; difficulty?: QuestionDifficulty},
  Question[]
> = async ({plantId, topicId, difficulty}) => {
  const params = difficulty ? `?difficulty=${difficulty}` : ''
  const {data} = await dfApi.get<Question[]>(
    `/pom-questionnaires/${plantId}/topics/${topicId}/questions${params}`
  )
  return data
}

export const getQuestions = loggingDecorator(getQuestionsImpl)
