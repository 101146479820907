import {CancelButton} from '@hconnect/uikit/src/lib2'
import {Check} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Box} from '@mui/material'
import {isEmpty, isEqual} from 'lodash'
import React, {FC, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {validateActionItem} from '../../common/validator/actionValidator'
import {SideCardContainer} from '../../containers/SideCardContainer'
import {useEditAction} from '../../hooks/api/useEditAction'
import {useSectionById} from '../../hooks/api/useSection'
import {useActionId} from '../../hooks/urlParameters/useSearchParameter'
import {ActionItem} from '../../types'
import {MoveQuestionToDone} from '../actionsCompleted/MoveQuestionToDone'

import {ActionForm} from './ActionForm'
import {ActionsDetails} from './ActionsDetails'
import {ActionsMoreButton} from './ActionsMoreButton'

type ActionSideCardProps = {
  selectedAction?: ActionItem
}

export const ActionSideCard: FC<ActionSideCardProps> = ({selectedAction}) => {
  const {t} = useTranslation()
  const [, setActionId] = useActionId()
  const [isEditMode, setIsEditMode] = useState(false)
  const [formValues, setFormValues] = React.useState<Partial<ActionItem>>({})

  const onEdit = useCallback(
    (action: ActionItem) => {
      setActionId(action.id)
    },
    [setActionId]
  )

  const onActionDetailsClose = useCallback(() => {
    setActionId(undefined)
  }, [setActionId])

  const validationErrors = useMemo(
    () => (formValues ? validateActionItem(formValues) : {}),
    [formValues]
  )
  const isFormValid = isEmpty(validationErrors)

  const handleInputChange = (key: keyof ActionItem, value) => {
    setFormValues((values) => ({...values, [key]: value}))
  }

  const handleClose = () => {
    setIsEditMode(false)
    setFormValues({})
  }

  const [showMoveQuesDialog, setShowMoveQuesDialog] = useState<boolean>(false)
  const {mutate: editAction, isLoading} = useEditAction(({showMoveQuestToDone}) => {
    handleClose()
    showMoveQuestToDone && setShowMoveQuesDialog(true)
  })

  const {data: section} = useSectionById(selectedAction?.section?.id)

  const questionAnswer = useMemo(
    () =>
      section?.topics
        .find((topic) => selectedAction?.topic?.id === topic.id)
        ?.questions?.find((question) => selectedAction?.questionId === question.id)?.submission
        ?.answer,
    [section?.topics, selectedAction?.questionId, selectedAction?.topic?.id]
  )

  const handleSubmit = () => {
    editAction({actionItem: formValues as ActionItem, questionAnswer})
  }

  const saveDisabled = isEqual(selectedAction, formValues)

  return (
    <>
      {selectedAction && !isEditMode && (
        <SideCardContainer
          title={selectedAction.title}
          onClose={onActionDetailsClose}
          actions={
            <ActionsMoreButton
              moreButtonTestId="action-card-more-button"
              item={selectedAction}
              enableEditMode={(actionToEdit) => {
                setActionId(actionToEdit.id)
                setFormValues(actionToEdit)
                setIsEditMode(true)
              }}
            />
          }
        >
          <ActionsDetails
            onChange={onEdit}
            action={selectedAction}
            questionAnswer={questionAnswer}
          />
        </SideCardContainer>
      )}
      {selectedAction && isEditMode && formValues && (
        <SideCardContainer title={t('editAction.heading')} data-test-id="action-edit-form">
          <ActionForm
            formValues={formValues}
            validationErrors={validationErrors}
            handleInputChange={handleInputChange}
            actionItem={selectedAction}
          />
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <CancelButton onClick={handleClose} data-test-id="cancel-action">
              {t('button.cancel')}
            </CancelButton>
            <LoadingButton
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={!isFormValid || saveDisabled}
              startIcon={<Check />}
              data-test-id="save-action"
              loading={isLoading}
              loadingPosition={'start'}
            >
              {t('button.save')}
            </LoadingButton>
          </Box>
        </SideCardContainer>
      )}

      {showMoveQuesDialog && selectedAction && (
        <MoveQuestionToDone
          actionItem={selectedAction}
          onClose={() => setShowMoveQuesDialog(false)}
        />
      )}
    </>
  )
}
