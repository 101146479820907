import {Add} from '@mui/icons-material'
import {Button} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {QuestionnaireContainer} from '../../containers/QuestionnaireContainer'
import {Tab, TabSectionContainer} from '../../containers/TabSectionContainer'
import {useQuestions} from '../../hooks/api/useQuestions'
import {ActionItem} from '../../types'
import {ActionDialogWrapper} from '../actionDialog/ActionDialogWrapper'
import {ActionsCard} from '../actions/ActionsCard'

export enum PlantCardTab {
  Questions,
  Actions
}

type TopicSideCardProps = {
  actions: ActionItem[]
}

export const TopicSideCard: FC<TopicSideCardProps> = ({actions}) => {
  const {t} = useTranslation()
  const questionList = useQuestions()

  const tabs: Tab<PlantCardTab>[] = [
    {
      index: PlantCardTab.Questions,
      label: t('questionsOverview.title', {questionsCount: questionList.data?.length}),
      component: <QuestionnaireContainer />
    },
    {
      index: PlantCardTab.Actions,
      label: t('actionsCard.title', {actionsCount: actions.length}),
      component: <ActionsCard actions={actions} />
    }
  ]

  return (
    <TabSectionContainer<PlantCardTab>
      tabs={tabs}
      initialTab={PlantCardTab.Questions}
      getTabAction={(tab) =>
        tab === PlantCardTab.Actions && (
          <ActionDialogWrapper
            opener={
              <Button
                sx={{height: 48}}
                variant={'text'}
                startIcon={<Add />}
                data-test-id="add-action"
              >
                {t('addAction.heading')}
              </Button>
            }
          />
        )
      }
    />
  )
}
