import {InfoOutlined} from '@mui/icons-material'
import {Typography} from '@mui/material'
import React from 'react'

export const ErrorMessage = ({children}: {children?: React.ReactNode}) => (
  <Typography
    align="center"
    sx={(theme) => ({
      color: theme.palette.grey[700]
    })}
  >
    <InfoOutlined
      sx={{
        mr: 1,
        verticalAlign: 'text-bottom'
      }}
    />
    {children}
  </Typography>
)
