import {useQuery} from 'react-query'

import {QueryKeyName, getSection} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'
import {useSectionId} from '../urlParameters/useSectionId'

export const useSection = () => {
  const plantId = usePlantId()
  const sectionId = useSectionId()

  return useQuery(
    [QueryKeyName.GetSection, plantId, sectionId],
    () => getSection({plantId, sectionId}),
    {
      refetchOnWindowFocus: false
    }
  )
}

export const useSectionById = (sectionId?: string) => {
  const plantId = usePlantId()

  return useQuery(
    [QueryKeyName.GetSection, plantId, sectionId],
    () => getSection({plantId, sectionId: sectionId || ''}),
    {
      refetchOnWindowFocus: false,
      enabled: !!sectionId
    }
  )
}
