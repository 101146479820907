import {styled} from '@mui/material'

export const StyledGreen = styled('span')(
  ({theme}) => `
  color: ${theme.palette.success.dark}
`
)

export const StyledRed = styled('span')(
  ({theme}) => `
  color: ${theme.palette.error.main}
`
)
