import {Box} from '@mui/material'
import {map} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useQuestionActions} from '../../hooks/api/useQuestionActions'
import {QuestionItem} from '../../types'
import {ActionDialogWrapper} from '../actionDialog/ActionDialogWrapper'
import {ActionsListItem} from '../actions/ActionsListItem'

import {DefaultAddButton, AddInformationHeader} from './AddInformationHeader'

type Props = {
  question: QuestionItem
}

export const Actions: React.FC<Props> = ({question}) => {
  const {t} = useTranslation()
  const {data: actions} = useQuestionActions(question.id)

  return (
    <Box my={3}>
      <AddInformationHeader title={t('actions.heading')}>
        <ActionDialogWrapper
          connectedTo={question}
          opener={<DefaultAddButton data-test-id="add-action-button" />}
        />
      </AddInformationHeader>
      {map(actions, (a) => (
        <ActionsListItem key={a.id} item={a} />
      ))}
    </Box>
  )
}
