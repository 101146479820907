import {compressFile} from '@hconnect/common/files'
import {compact} from 'lodash'

import {RequestFunc} from '../../types'
import {Attachment} from '../../types/backend.types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'

const addAttachmentsToQuestionImpl: RequestFunc<
  {plantId: string; questionId: string; files: File[]},
  Attachment[]
> = async ({plantId, questionId, files}) => {
  const compressedFiles: File[] = await Promise.all(files.map((file) => compressFile(file)))
  const formData = new FormData()
  compressedFiles.forEach((file) => {
    formData.append('attachments', file, file.name)
  })
  const {data} = await dfApi.post<Attachment[]>(
    `/pom-questionnaires/${plantId}/questions/${questionId}/attachments`,
    formData
  )
  return data
}
export const addAttachmentsToQuestion = loggingDecorator(addAttachmentsToQuestionImpl)

const deleteAttachmentImpl: RequestFunc<
  {plantId: string; questionId: string; documentId: string},
  void
> = async ({plantId, questionId, documentId}) => {
  await dfApi.delete(
    `/pom-questionnaires/${plantId}/questions/${questionId}/attachments?documentId=${documentId}`
  )
}
export const deleteAttachment = loggingDecorator(deleteAttachmentImpl)

export const getFile = async (url: string): Promise<ArrayBuffer> => {
  const {data} = await dfApi.get<ArrayBuffer>(`/documents/${url}`, {
    responseType: 'arraybuffer'
  })
  return data
}

interface AttachmentFiles {
  file: File
  previewFile?: File
}

export const getAttachmentFiles: RequestFunc<Attachment, AttachmentFiles | undefined> = async (
  attachment: Attachment
) => {
  const {fileName, url, previewUrl, mediaType} = attachment
  if (!url) {
    return undefined
  }
  const urls: string[] = compact([url, previewUrl])
  const buffers: ArrayBuffer[] = await Promise.all(urls.map((fileUrl) => getFile(fileUrl)))
  const files: File[] = buffers.map((buffer) => new File([buffer], fileName, {type: mediaType}))
  return {file: files[0], previewFile: files[1]}
}
