import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'
import React, {useCallback, useMemo} from 'react'

import {CompletionCard} from '../components/completion/CompletionCard'
import {QuestionListSideCard} from '../components/questionListSideCard'
import {TopicSideCard} from '../components/topicSideCard'
import {useTopicActions} from '../hooks/api/useTopicActions'
import {useStringSearchParameter} from '../hooks/urlParameters/useSearchParameter'
import {useQuestionState} from '../hooks/useQuestionState'
import {Topic} from '../types'
import {Question} from '../types/backend.types'
import {countAllQuestionsSubmissions} from '../utils'

import {SectionTopContainer} from './SectionTopContainer'

interface TopicContainerProps {
  topic: Topic
}

export const TopicContainer: React.FC<TopicContainerProps> = ({topic}) => {
  const {data: actions, status} = useTopicActions(topic.id)
  const [, setQuestionId] = useStringSearchParameter('selectedQuestionId')

  const [{activeAnswerStatus, activeDifficulty}, {setActiveDifficulty, setActiveAnswerStatus}] =
    useQuestionState()

  const onScoreSectionClick = useCallback(
    (status?: AnswerStatus) => {
      setActiveAnswerStatus(status)
      setQuestionId(undefined)
    },
    [setActiveAnswerStatus, setQuestionId]
  )
  const onDifficultySectionClick = useCallback(
    (difficulty: QuestionDifficulty) => {
      setActiveDifficulty(difficulty)
      setQuestionId(undefined)
    },
    [setActiveDifficulty, setQuestionId]
  )

  const submissionSummary = useMemo(() => {
    if (!topic?.questions) return undefined
    return countAllQuestionsSubmissions(topic.questions, activeDifficulty)
  }, [activeDifficulty, topic])

  const filterQuestions = useCallback(({id}: Question) => id.includes(topic.id), [topic.id])

  return (
    <SectionTopContainer
      actionLoadingStatus={status}
      isLoading={!actions}
      sideCardComponent={
        activeAnswerStatus && submissionSummary ? (
          <QuestionListSideCard
            difficulty={submissionSummary.difficulty}
            customQuestionsFilterFunction={filterQuestions}
          />
        ) : (
          <TopicSideCard actions={actions || []} />
        )
      }
    >
      <CompletionCard
        activeDifficulty={activeDifficulty}
        onScoreSectionClick={onScoreSectionClick}
        onDifficultySectionClick={onDifficultySectionClick}
        responsiblePerson={topic.responsible}
        submissionSummary={submissionSummary}
        difficultySummaries={topic.difficultySummaries}
      />
    </SectionTopContainer>
  )
}
