import {TextField} from '@mui/material'
import {debounce, noop} from 'lodash'
import React, {useState, useEffect} from 'react'
import {usePrevious} from 'react-use'

import {SEARCH_DELAY_IN_MS} from '../../common/constants'

import {textFieldOnBlueSx} from './filterStyles'

interface FilterTextFieldProps {
  value?: string
  onChange?: (value: string) => void
  label?: string
  'data-test-id'?: string
}

export const FilterTextField: React.FC<FilterTextFieldProps> = ({
  value,
  onChange = noop,
  label
}) => {
  const [text, setText] = useState(value)
  const prevText = usePrevious(text)

  useEffect(() => {
    if (text !== prevText) {
      const onDebounced = debounce(onChange, SEARCH_DELAY_IN_MS)
      onDebounced(text)
      return () => onDebounced.cancel()
    }
  }, [onChange, prevText, text])

  return (
    <TextField
      variant={'filled'}
      sx={textFieldOnBlueSx}
      label={label}
      value={text}
      onChange={(event) => setText(event.target.value)}
      data-test-id={'filter-control-title'}
    />
  )
}
