import {customThemeConstants, mergeSxs} from '@hconnect/uikit/src/lib2'
import {SxProps, Theme} from '@mui/material'

export const textFieldOnBlueSx: SxProps<Theme> = {
  minWidth: 208,
  '& .MuiInputBase-root': {
    borderColor: customThemeConstants().palette.mediumInputBorder,
    '&:hover': {
      borderColor: 'common.white'
    },
    '&.Mui-focused': {
      borderColor: 'primary.main'
    }
  },
  '& label.MuiFormLabel-root': {
    color: customThemeConstants().palette.mediumInputBorder,
    '&:hover': {
      color: 'common.white'
    }
  },
  '& input:-webkit-autofill, input:-webkit-autofill:focus, input:-webkit-autofill:hover': {
    // HACK: this is a rather ugly hack to prevent the Browser from overriding the text field's color
    // see also https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },
  '& .MuiOutlinedInput-root': {
    color: 'primary.contrastText',
    backgroundColor: 'transparent',
    '& fieldset': {
      borderColor: customThemeConstants().palette.mediumInputBorder
    },
    '&:hover fieldset': {
      borderColor: 'common.white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.main'
    }
  },
  '& .MuiFilledInput-root': {
    color: 'primary.contrastText',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent'
    }
  }
}

export const selectFieldOnBlueSx: SxProps<Theme> = mergeSxs(textFieldOnBlueSx, {
  '& .MuiSvgIcon-root': {
    color: 'primary.contrastText'
  }
})
