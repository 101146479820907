import {RatingTooltip} from '@hconnect/common/components/completion'
import {QuestionDifficulty} from '@hconnect/common/types'
import {completionScoreBarColor, formatCompletion} from '@hconnect/common/utils'
import {datetimeFormatter} from '@hconnect/uikit'
import {Flag, GaugeBar} from '@hconnect/uikit/src/lib2'
import {Theme, Typography} from '@mui/material'
import i18next from 'i18next'
import React from 'react'

import {LocationBaseTableEntry} from '../../types'

export type PlantTableColumnKey = keyof LocationBaseTableEntry | QuestionDifficulty

export const PLANT_COLUMN_IDS: PlantTableColumnKey[] = [
  'name',
  QuestionDifficulty.Basic,
  QuestionDifficulty.Advanced,
  QuestionDifficulty.Excellence,
  'responsible',
  'lastUpdate'
]

export const SIDE_PROGRESS_COLUMN_PADDING = 0.25
export const MIDDLE_PROGRESS_COLUMN_PADDING = 0.125

export const getScoreCellStyle = (key: string) => {
  if (key === 'basic') {
    return {
      pr: SIDE_PROGRESS_COLUMN_PADDING,
      py: 1.25
    }
  }
  if (key === 'advanced') {
    return {
      px: MIDDLE_PROGRESS_COLUMN_PADDING,
      py: 1.25
    }
  }
  if (key === 'excellence') {
    return {
      pl: SIDE_PROGRESS_COLUMN_PADDING,
      py: 1.25
    }
  }
}

const LocationCellContent: React.FC<{item: LocationBaseTableEntry}> = ({item}) => (
  <Typography component={'span'}>
    {item.countryCode && (
      <Flag
        countryCode={item.countryCode}
        alt={item.name}
        height={18}
        sx={{
          verticalAlign: 'text-bottom',
          mr: 1
        }}
      />
    )}
    {item.name}
  </Typography>
)

export const renderPlantTableCell = <T extends LocationBaseTableEntry>(
  item: T,
  columnIndex: number,
  columnKey: string
): React.ReactNode => {
  switch (columnKey) {
    case 'name': {
      return <LocationCellContent item={item} />
    }
    case 'basic':
      return (
        <RatingTooltip
          value={item.difficultySummaries.basic.positivePercent}
          summaries={item.difficultySummaries}
          hideTotal
        >
          <GaugeBar
            value={item.difficultySummaries.basic.answeredPositively}
            maxValue={item.difficultySummaries.basic.questionCount}
            color={completionScoreBarColor(item.difficultySummaries, QuestionDifficulty.Basic)}
            minLabelWidth={56}
            customSubtitle={
              <Typography variant="subtitle2">
                {item.difficultySummaries.basic.answeredPositively}/
                {item.difficultySummaries.basic.questionCount}
              </Typography>
            }
            data-test-id={`dashboardTable-row-${item.id}-${columnKey}`}
          />
        </RatingTooltip>
      )
    case 'advanced':
      return (
        <GaugeBar
          value={item.difficultySummaries.advanced.answeredPositively}
          maxValue={item.difficultySummaries.advanced.questionCount}
          color={completionScoreBarColor(item.difficultySummaries, QuestionDifficulty.Advanced)}
          format={formatCompletion}
          minLabelWidth={56}
          customSubtitle={
            <Typography variant="caption">
              {item.difficultySummaries.advanced.answeredPositively}/
              {item.difficultySummaries.advanced.questionCount}
            </Typography>
          }
          data-test-id={`dashboardTable-row-${item.id}-${columnKey}`}
        />
      )
    case 'excellence':
      return (
        <GaugeBar
          value={item.difficultySummaries.excellence.answeredPositively}
          maxValue={item.difficultySummaries.excellence.questionCount}
          color={completionScoreBarColor(item.difficultySummaries, QuestionDifficulty.Excellence)}
          format={formatCompletion}
          minLabelWidth={56}
          customSubtitle={
            <Typography variant="caption">
              {item.difficultySummaries.excellence.answeredPositively}/
              {item.difficultySummaries.excellence.questionCount}
            </Typography>
          }
          data-test-id={`dashboardTable-row-${item.id}-${columnKey}`}
        />
      )
    case 'lastUpdate':
      return (
        <Typography
          sx={(theme: Theme) => ({
            color: theme.palette.grey[600],
            fontSize: 12
          })}
        >
          {item.lastUpdate ? datetimeFormatter(item.lastUpdate, i18next.language) : ''}
        </Typography>
      )
    case 'responsible':
      return <Typography fontSize={12}>{item.responsible}</Typography>
  }
  return item[columnKey]
}
