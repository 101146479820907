import {AxiosError} from 'axios'
import {NavigateFunction} from 'react-router-dom'

import {ERROR_403} from '../common/routing'

export const handleError = (err: AxiosError, navigate: NavigateFunction): void => {
  if (err.response?.status === 403) {
    navigate(ERROR_403)
  }
}

export const defaultRetryTries = 3
export const isNetworkError = (error: AxiosError) => error.message === 'Network Error'
export const isServerError = (error: AxiosError) => error.response?.status === 500

export const retryFunction = (failureCount: number, error: AxiosError): boolean =>
  failureCount < defaultRetryTries && (isNetworkError(error) || isServerError(error))
