import React, {FC, ReactElement, cloneElement, useState} from 'react'

import {ActionItem, QuestionItem} from '../../types'

import {CreateActionDialog} from './CreateActionDialog'

interface Props {
  connectedTo?: QuestionItem
  opener: ReactElement
  onActionCreate?: (action: ActionItem) => void
}

export const ActionDialogWrapper: FC<Props> = ({connectedTo, onActionCreate, opener}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {cloneElement(opener, {onClick: () => setOpen(true)})}
      <CreateActionDialog
        open={open}
        handleOpen={setOpen}
        relatedQuestion={connectedTo}
        onActionCreate={onActionCreate}
      />
    </>
  )
}
