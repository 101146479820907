import {CompletionSection} from '@hconnect/common/components/completion'
import {
  AnswerStatus,
  DifficultySummaries,
  QuestionDifficulty,
  UserDto
} from '@hconnect/common/types'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import React from 'react'

import {usePlantContext} from '../../hooks/usePlantContext'
import {SubmissionSummary} from '../../utils'
import {ScoreSection} from '../score'

import {ResponsiblePerson} from './ResponsiblePerson'

interface CompletionCardProps {
  difficultySummaries: DifficultySummaries
  onScoreSectionClick: (status?: AnswerStatus) => void
  onDifficultySectionClick: (difficulty: QuestionDifficulty) => void
  activeDifficulty: QuestionDifficulty
  responsiblePerson?: UserDto
  submissionSummary?: SubmissionSummary
}

export const CompletionCard: React.FC<CompletionCardProps> = ({
  responsiblePerson,
  difficultySummaries,
  submissionSummary,
  activeDifficulty,
  onScoreSectionClick,
  onDifficultySectionClick
}) => {
  const {data} = usePlantContext()

  return (
    <CardBox data-test-id="completion-card">
      <Typography variant="h3" sx={{mb: 3}}>
        {data?.name}
      </Typography>
      <CompletionSection
        activeDifficulty={activeDifficulty}
        difficultySummaries={difficultySummaries}
        onDifficultySectionClick={onDifficultySectionClick}
        data-test-id="completion-card-progress"
      />
      {submissionSummary && (
        <ScoreSection
          onScoreSectionClick={onScoreSectionClick}
          submissionSummary={submissionSummary}
          data-test-id="score-card-progress"
        />
      )}
      <ResponsiblePerson person={responsiblePerson} />
    </CardBox>
  )
}
