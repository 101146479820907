import {ErrorMessage, CardBox, PageContainer} from '@hconnect/uikit/src/lib2'
import React from 'react'

export const ErrorPage: React.FC<{errorMessage: React.ReactNode}> = ({errorMessage}) => {
  return (
    <PageContainer>
      <CardBox>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </CardBox>
    </PageContainer>
  )
}
