import {Box, Typography, Theme, Link} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, Link as RouterLink} from 'react-router-dom'

import {PLANT_SUB_SECTION} from '../../common/routing'
import {usePlantId} from '../../hooks/urlParameters/usePlantId'
import {ActionItem} from '../../types'

type ActionConnectedToSectionProps = {
  action: ActionItem
}

export const ActionConnectedToSectionLink: FC<ActionConnectedToSectionProps> = ({action}) => {
  const {section, topic, questionId} = action
  const {t} = useTranslation()
  const plantId = usePlantId()

  return (
    <Box data-test-id="action-connected-to-link" pb={3}>
      <Typography
        sx={(theme: Theme) => ({
          color: theme.palette.grey[600]
        })}
        variant="caption"
        display="flex"
      >
        {t('actionDialog.connectedTo')}
      </Typography>
      <Link
        component={RouterLink}
        to={{
          pathname: generatePath(PLANT_SUB_SECTION, {
            plantId,
            sectionId: section?.id,
            topicId: topic?.id
          }),
          search: `?selectedQuestionId=${questionId}`
        }}
        sx={{textDecoration: 'none', fontWeight: 600}}
      >
        {questionId}
      </Link>
    </Box>
  )
}
