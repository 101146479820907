import React from 'react'
import {useTranslation} from 'react-i18next'

import {dueDate} from '../../common/domain/date'
import {actionValidationConfig} from '../../common/validator/actionValidator'
import {validateItem} from '../../common/validator/validator'
import {useCreateAction} from '../../hooks/api/useCreateAction'
import {useTimeZone} from '../../hooks/useTimeZone'
import {QuestionItem, CreateActionItem, ActionItem} from '../../types'

import {ActionDialog} from './ActionDialog'

type Props = {
  open: boolean
  handleOpen: (open: boolean) => void
  onActionCreate?: (item: ActionItem) => void
  relatedQuestion?: QuestionItem
}

const resetFormValues = (
  dueDate: Date,
  relatedQuestion?: QuestionItem
): Partial<CreateActionItem> => {
  const initialValues: Partial<CreateActionItem> = {
    dueDate,
    title: '',
    description: ''
  }
  return relatedQuestion
    ? {
        ...initialValues,
        questionId: relatedQuestion.id,
        source: 'pom'
      }
    : initialValues
}

const validateCreateActionItem = (item: Partial<CreateActionItem>) =>
  validateItem(actionValidationConfig(), item)

export const CreateActionDialog: React.FC<Props> = ({
  open,
  handleOpen,
  onActionCreate,
  relatedQuestion
}) => {
  const timeZone = useTimeZone()
  const {t} = useTranslation()
  const {mutate: createAction, isLoading: creating} = useCreateAction((item) => {
    handleOpen(false)
    onActionCreate?.(item)
  })

  const actionItem = resetFormValues(dueDate(Date.now(), timeZone), relatedQuestion)

  const handleSubmit = (item: Partial<CreateActionItem>) => {
    createAction(item as CreateActionItem)
  }

  return (
    <ActionDialog
      open={open}
      handleOpen={handleOpen}
      actionItem={actionItem}
      onSave={handleSubmit}
      onValidate={validateCreateActionItem}
      saving={creating}
      title={t('addAction.heading')}
    />
  )
}
