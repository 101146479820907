import {AnswerStatus} from '@hconnect/common/types'
import {CancelButton} from '@hconnect/uikit/src/lib2'
import {Check} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {useSubmitAnswer} from '../../hooks/api/useSubmitAnswer'
import {ActionItem} from '../../types'
import {StyledGreen, StyledRed} from '../common/StyledText'

type Props = {
  actionItem: ActionItem
  onClose: () => void
}

export const MoveQuestionToDone: React.FC<Props> = ({actionItem, onClose}) => {
  const {t} = useTranslation()
  const {plantId, questionId} = actionItem

  const {mutate: submitAnswer, isLoading} = useSubmitAnswer()

  const handleSubmit = () => {
    submitAnswer({
      plantId,
      questionId,
      answer: AnswerStatus.Positive
    })
    onClose()
  }

  return (
    <Dialog open={true} onClose={onClose} data-test-id="move-ques-dialog">
      <DialogTitle>{t('moveToDone.title')}</DialogTitle>
      <DialogContent>
        <Trans
          i18nKey="moveToDone.desc"
          components={{
            green: <StyledGreen />,
            red: <StyledRed />
          }}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} data-test-id="cancel-move">
          {t('button.cancel')}
        </CancelButton>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          startIcon={<Check />}
          data-test-id="move-to-yes"
          loading={isLoading}
          loadingPosition={'start'}
        >
          {t('button.ok')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
