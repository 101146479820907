import {Button} from '@mui/material'
import React from 'react'

interface AnswerButtonProps {
  text: string
  isSelected: boolean
  color: undefined | 'success' | 'error' | 'secondary'
  startIcon: JSX.Element
  onClick: () => void
}

export const AnswerButton: React.FC<AnswerButtonProps> = (props) => {
  const {text, color: colorProp, isSelected, ...rest} = props
  const color = isSelected ? colorProp : 'secondary'
  return (
    <Button variant={isSelected ? 'contained' : 'outlined'} color={color} {...rest}>
      {text}
    </Button>
  )
}
