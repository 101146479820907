import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {validateActionItem} from '../../common/validator/actionValidator'
import {useEditAction} from '../../hooks/api/useEditAction'
import {useSectionById} from '../../hooks/api/useSection'
import {ActionItem} from '../../types'
import {MoveQuestionToDone} from '../actionsCompleted/MoveQuestionToDone'

import {ActionDialog} from './ActionDialog'

type Props = {
  open: boolean
  handleOpen: (open: boolean) => void
  actionItem: ActionItem
}

export const EditActionDialog: React.FC<Props> = ({open, handleOpen, actionItem}) => {
  const {t} = useTranslation()
  const [showMoveQuesDialog, setShowMoveQuesDialog] = useState<boolean>(false)
  const {mutate: editAction, isLoading} = useEditAction(({showMoveQuestToDone}) => {
    handleOpen(false)
    showMoveQuestToDone && setShowMoveQuesDialog(true)
  })
  const {data: section} = useSectionById(actionItem.section?.id)
  const questionAnswer = useMemo(
    () =>
      section?.topics
        .find((topic) => actionItem.topic?.id === topic.id)
        ?.questions?.find((question) => actionItem.questionId === question.id)?.submission?.answer,
    [section?.topics, actionItem.questionId, actionItem.topic?.id]
  )

  const handleSubmit = (item: Partial<ActionItem>) => {
    editAction({actionItem: item as ActionItem, questionAnswer})
  }

  return (
    <>
      <ActionDialog
        open={open}
        handleOpen={handleOpen}
        actionItem={actionItem}
        onSave={handleSubmit}
        onValidate={validateActionItem}
        saving={isLoading}
        title={t('editAction.heading')}
      />
      {showMoveQuesDialog && (
        <MoveQuestionToDone actionItem={actionItem} onClose={() => setShowMoveQuesDialog(false)} />
      )}
    </>
  )
}
