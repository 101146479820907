import {useParams} from 'react-router'

import {PathParameters} from '../../common/routing'

export const useTopicId = (): string => {
  const id = useParams<PathParameters>().topicId
  if (!id) {
    throw new Error('The topicId is not set in the URL.')
  }

  return id
}
