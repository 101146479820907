import {PlantDto} from '@hconnect/common/types'

import {Plant, RequestFunc} from '../../types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'
import {convertSection} from './sections'

export const convertPlantData = (dto: PlantDto): Plant => ({
  id: dto.plantId,
  name: dto.name,
  score: dto.scoring.score,
  completion: dto.scoring.completion,
  responsible: dto.responsible,
  sections: dto.sections.map(convertSection),
  timezone: dto.timezone,
  difficultySummaries: dto.scoring.difficultySummaries
})

const getPlantImpl: RequestFunc<string, Plant> = async (plantId: string) => {
  const {data} = await dfApi.get<PlantDto>(`/pom-questionnaires/${plantId}`)
  return convertPlantData(data)
}
export const getPlant = loggingDecorator(getPlantImpl)

const assignPlantResponsibleImpl: RequestFunc<{plantId: string; userId: string}, void> = async ({
  plantId,
  userId
}) => {
  await dfApi.post(`/pom-questionnaires/${plantId}/responsible`, {userId})
}
export const assignPlantResponsible = loggingDecorator(assignPlantResponsibleImpl)

const downloadPlantSubmissionsImpl: RequestFunc<string, Blob> = async (plantId: string) => {
  const {data} = await dfApi.get<Blob>(`/pom-questionnaires/${plantId}/excel-sheet`, {
    responseType: 'blob'
  })
  return data
}

export const downloadPlantSubmissions = loggingDecorator(downloadPlantSubmissionsImpl)
