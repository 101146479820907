import {Add} from '@mui/icons-material'
import {Button} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {SectionsOverview} from '../../containers/SectionsOverview'
import {Tab, TabSectionContainer} from '../../containers/TabSectionContainer'
import {ActionItem, Category} from '../../types'
import {ActionDialogWrapper} from '../actionDialog/ActionDialogWrapper'
import {ActionsCard} from '../actions/ActionsCard'

export enum PlantCardTab {
  Categories,
  Actions
}

type PlantSideCardProps = {
  actions: ActionItem[]
  sections: Category[]
  onSectionRowClick: (id: string) => void
}

export const PlantSideCard: FC<PlantSideCardProps> = ({actions, onSectionRowClick, sections}) => {
  const {t} = useTranslation()

  const tabs: Tab<PlantCardTab>[] = [
    {
      index: PlantCardTab.Categories,
      label: t('sectionsOverview.title', {sectionsCount: sections.length}),
      component: <SectionsOverview sections={sections} onRowClick={onSectionRowClick} />
    },
    {
      index: PlantCardTab.Actions,
      label: t('actionsCard.title', {actionsCount: actions.length}),
      component: <ActionsCard actions={actions} />
    }
  ]

  return (
    <TabSectionContainer<PlantCardTab>
      tabs={tabs}
      initialTab={PlantCardTab.Categories}
      getTabAction={(tab) =>
        tab === PlantCardTab.Actions && (
          <ActionDialogWrapper
            opener={
              <Button
                sx={{height: 48}}
                variant={'text'}
                startIcon={<Add />}
                data-test-id="add-action"
              >
                {t('addAction.heading')}
              </Button>
            }
          />
        )
      }
    />
  )
}
