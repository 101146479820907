import {Done, HourglassEmpty, Loop} from '@mui/icons-material'
import {Box, styled, Typography, css} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ActionItem} from '../../types'
import {ActionStatusType} from '../../types/backend.types'

import {ActionsMoreButton} from './ActionsMoreButton'

const statusIconCss = css`
  margin-right: 8px;
`

const StyledPendingIcon = styled(HourglassEmpty)`
  color: ${({theme}) => theme.palette.error.light};
  ${statusIconCss};
`

const StyledOngoingIcon = styled(Loop)`
  color: ${({theme}) => theme.palette.info.light};
  ${statusIconCss};
`

const StyledDoneIcon = styled(Done)`
  color: ${({theme}) => theme.palette.success.light};
  ${statusIconCss};
`

const renderIconByActionStatus = (status: ActionStatusType) => {
  switch (status) {
    case 'pending':
      return <StyledPendingIcon fontSize="small" />
    case 'ongoing':
      return <StyledOngoingIcon fontSize="small" />
    case 'done':
      return <StyledDoneIcon fontSize="small" />
  }
}

interface ActionsListItemProps {
  item: ActionItem
}

export const ActionsListItem: React.FC<ActionsListItemProps> = ({item}) => {
  const {t} = useTranslation()

  return (
    <Box
      data-test-id={`action-list-item-${item.id}`}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      minHeight="56px"
      py="10px"
      sx={(theme) => ({borderBottom: `1px solid ${theme.palette.divider}`})}
    >
      <Typography variant="subtitle1">{item.title}</Typography>
      <Box display="flex" alignItems="center">
        {renderIconByActionStatus(item.status)}

        <Typography variant="subtitle1" marginRight="20px">
          {t(`actions.${item.status}`)}
        </Typography>

        <ActionsMoreButton
          item={item}
          moreButtonTestId={`actions-list-more-menu-button-${item.id}`}
        />
      </Box>
    </Box>
  )
}
