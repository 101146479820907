import {AxiosError} from 'axios'

import {RequestFunc} from '../../types'
import {logger} from '../logger'

export const loggingDecorator =
  <P, T>(requestFunc: RequestFunc<P, T>, options?: {context?: string}): RequestFunc<P, T> =>
  async (params: P) => {
    const log = logger.context(options?.context || `${requestFunc.name}(${JSON.stringify(params)})`)

    try {
      return await requestFunc(params)
    } catch (e: unknown) {
      log.error(e as AxiosError)
      throw e
    }
  }
