import {AxiosError} from 'axios'
import React, {createContext, useContext} from 'react'
import {UseQueryResult} from 'react-query'

import {Plant} from '../types'

import {usePlant} from './api/usePlant'
import {usePlantId} from './urlParameters/usePlantId'

type PlantData = UseQueryResult<Plant, AxiosError>
const Context = createContext<PlantData | undefined>(undefined)

export const PlantContextProvider = ({children}: {children?: React.ReactNode}) => {
  const plantId = usePlantId()

  const data = usePlant(plantId)

  return <Context.Provider value={data}>{children}</Context.Provider>
}

export const usePlantContext = () => {
  const ctx = useContext(Context)
  if (ctx === undefined) {
    throw new Error(
      'usePlantContext used outside of PlantContextProvider or app-config not available'
    )
  } else {
    return ctx
  }
}
