import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {downloadSectionSubmissions} from '../../common/backend/sections'
import {downloadBlob} from '../../common/downloadBlob'

export const useSectionSubmissionsDownload = (plantId: string, sectionId: string) => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()

  return useMutation(() => downloadSectionSubmissions({plantId, sectionId}), {
    onSuccess: (blob) => {
      downloadBlob(blob, `${plantId}_${sectionId}_submissions.xlsx`)
    },
    onError: () => {
      enqueueSnackbar(t('errors.excelDownloadFailed'), {variant: 'error'})
    }
  })
}
