import {orderBy} from 'lodash'

import {
  LocationStats,
  PlantLocationStats,
  LocationTreeTableEntry,
  PlantTableEntry,
  LocationType
} from '../types'

import {mapToFlatListWithKey, traverseWithKey} from './treeUtils'

export const PLANT_VIEWS = ['regionalStructure', 'plantList'] as const
export type PlantViewOption = (typeof PLANT_VIEWS)[number]

const traverseStats = (statsList: LocationStats[], op: (stats: LocationStats) => void) =>
  traverseWithKey('subLocations', statsList, op)

export const mapToDashboardEntries = (stats: LocationStats[]): LocationTreeTableEntry[] => {
  return stats.map(
    ({
      id,
      type,
      name,
      countryCode,
      difficultySummaries,
      responsible,
      lastUpdate,
      subLocations
    }) => ({
      id,
      type,
      name,
      countryCode,
      responsible: responsible?.name ?? '',
      lastUpdate,
      difficultySummaries,
      subItems: mapToDashboardEntries(subLocations)
    })
  )
}

export const extractPlantLocations = (statsList: LocationStats[]): PlantTableEntry[] => {
  const result: PlantTableEntry[] = []

  const plantCountryMap: Record<string, string> = {}

  traverseStats(statsList, (stats: LocationStats) => {
    const {
      id,
      name,
      type,
      countryCode,
      subLocations,
      difficultySummaries,
      responsible,
      lastUpdate
    } = stats
    if (type === LocationType.Country && countryCode) {
      subLocations.forEach((location) => {
        const plant = location as PlantLocationStats
        plantCountryMap[plant.id] = countryCode
      })
    } else if (type === LocationType.Plant) {
      result.push({
        id,
        name,
        countryCode: plantCountryMap[id],
        difficultySummaries,
        responsible: responsible?.name ?? '',
        lastUpdate
      })
    }
  })

  return orderBy(result, ['name'], ['asc'])
}

type PlantOverviewData = {
  showPlantOverview: boolean
  plantId?: string
}

export const getPlantRedirectData = (data?: LocationStats[]): PlantOverviewData => {
  if (!data) return {showPlantOverview: true}
  const plantList = mapToFlatListWithKey('subLocations', data || []).filter(
    (item) => item.type === LocationType.Plant
  )
  const showPlantOverview = plantList.length > 1
  const result: PlantOverviewData = {showPlantOverview}

  if (!showPlantOverview) {
    result.plantId = plantList[0]?.id
  }

  return result
}
