import {TableCell, TableSortLabel, SortDirection, TableRow, TableHead} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ActionItemKey} from '../../types'

interface ActionsTableHeaderCellProps {
  columnKey: ActionItemKey
  orderByKey?: ActionItemKey
  sortDir: SortDirection
  onSortClick: (key: ActionItemKey) => void
}

const tableHeaderCellSx = {
  '&:nth-of-type(1)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  '&:nth-last-of-type(1)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}

const ActionsTableHeaderCell: React.FC<ActionsTableHeaderCellProps> = ({
  columnKey,
  orderByKey,
  sortDir,
  onSortClick
}) => {
  const {t} = useTranslation()

  return (
    <TableCell sortDirection={orderByKey === columnKey && sortDir} sx={tableHeaderCellSx}>
      <TableSortLabel
        active={sortDir && orderByKey === columnKey}
        direction={orderByKey === columnKey && sortDir ? sortDir : undefined}
        onClick={() => onSortClick(columnKey)}
      >
        {t(`actionsTable.header.${columnKey}`)}
      </TableSortLabel>
    </TableCell>
  )
}

interface ActionsTableHeader {
  orderByKey?: ActionItemKey
  sortDir: SortDirection
  onSortClick: (key: ActionItemKey) => void
  visibleColumns: ActionItemKey[]
}

export const ActionsTableHeader: React.FC<ActionsTableHeader> = ({
  orderByKey,
  sortDir,
  onSortClick,
  visibleColumns
}) => {
  return (
    <TableHead>
      <TableRow>
        {visibleColumns.map((key) => (
          <ActionsTableHeaderCell
            key={key}
            columnKey={key}
            orderByKey={orderByKey}
            sortDir={sortDir}
            onSortClick={onSortClick}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}
