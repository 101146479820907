import {useSorting} from '@hconnect/common/hooks/useSorting'
import {Table, TableBody, TableContainer, SortDirection} from '@mui/material'
import {orderBy} from 'lodash'
import React, {useMemo} from 'react'

import {isActionOverdue} from '../../common/domain/actions'
import {ActionItem, ActionItemKey} from '../../types'

import {ActionRow} from './ActionRow'
import {ActionsTableHeader} from './ActionsTableHeader'

const VISIBLE_COLUMNS: ActionItemKey[] = ['title', 'assignee', 'dueDate']

const defaultSortActions = (actions: ActionItem[]): ActionItem[] =>
  orderBy(actions, ['dueDate'], ['desc'])

const sortActionList = (
  actions: ActionItem[],
  orderByKey: ActionItemKey,
  order: SortDirection
): ActionItem[] => {
  if (orderByKey === 'dueDate') {
    return orderBy(actions, [orderByKey], [order])
  }
  if (orderByKey === 'assignee') {
    return orderBy(actions, ['assignee.name', 'dueDate'], [order, 'desc'])
  }
  return orderBy(actions, [orderByKey, 'dueDate'], [order, 'desc'])
}

interface ActionsTableProps {
  actions: ActionItem[]
  onRowClick?: (action: ActionItem) => void
}

export const ActionsTable: React.FC<ActionsTableProps> = ({actions, onRowClick}) => {
  const defaultSortedActions = useMemo(() => defaultSortActions(actions), [actions])

  const {
    orderByKey,
    sortDir,
    sortedList: sortedActions,
    onSortClick
  } = useSorting<ActionItem, ActionItemKey>({
    initialOrderByKey: 'dueDate',
    initialSortDir: 'desc',
    sort: sortActionList,
    data: defaultSortedActions
  })

  const now = new Date()

  return (
    <TableContainer sx={{height: '100%'}}>
      <Table
        stickyHeader
        sx={{
          borderCollapse: 'collapse'
        }}
      >
        <ActionsTableHeader
          visibleColumns={VISIBLE_COLUMNS}
          onSortClick={onSortClick}
          sortDir={sortDir}
          orderByKey={orderByKey}
        />
        <TableBody>
          {sortedActions.map((action) => (
            <ActionRow
              visibleColumns={VISIBLE_COLUMNS}
              key={action.id}
              action={action}
              isOverdue={isActionOverdue(action, now)}
              onClick={onRowClick}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
