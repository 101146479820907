import {LandingPage, Loader} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Routes} from 'react-router'

import {APP_NAME} from './common/constants'
import {
  DASHBOARD,
  ROOT,
  PLANT_SECTION,
  PLANT_SUB_SECTION,
  ACTIONS,
  ERROR_403
} from './common/routing'
import {PomFooter} from './components/PomFooter'
import {DefaultShell} from './containers/DefaultShell'
import {PlantContextProvider} from './hooks/usePlantContext'
import {ActionsPage} from './pages/ActionsPage'
import {DashboardPage} from './pages/DashboardPage'
import {ErrorPage} from './pages/ErrorPage'
import {PlantSectionPage} from './pages/PlantSectionPage'
import {PlantsOverviewPage} from './pages/PlantsOverviewPage'
import {PlantSubSectionPage} from './pages/PlantSubSectionPage'
import {PomError403Page} from './pages/PomError403Page'
import {useAuthContext} from './providers/AuthProvider'

export const Main: React.FC = () => {
  const {isLoggedIn, isLoading, login} = useAuthContext()

  const {t} = useTranslation()

  if (isLoading) {
    return <Loader height="60vh" color="primary.contrastText" />
  }

  if (!isLoggedIn) {
    return (
      <LandingPage
        onClickButton={() => {
          login()
        }}
        loginButtonLabel={t('login.button')}
        footer={<PomFooter />}
      >
        <Typography variant="h1">{APP_NAME}</Typography>
      </LandingPage>
    )
  }

  return (
    <DefaultShell>
      <Routes>
        <Route path={ROOT} element={<PlantsOverviewPage />} />

        <Route path={ERROR_403} element={<PomError403Page />} />

        <Route
          path={DASHBOARD}
          element={
            <PlantContextProvider>
              <DashboardPage />
            </PlantContextProvider>
          }
        />

        <Route
          path={PLANT_SECTION}
          element={
            <PlantContextProvider>
              <PlantSectionPage />
            </PlantContextProvider>
          }
        />

        <Route
          path={PLANT_SUB_SECTION}
          element={
            <PlantContextProvider>
              <PlantSubSectionPage />
            </PlantContextProvider>
          }
        />

        <Route
          path={ACTIONS}
          element={
            <PlantContextProvider>
              <ActionsPage />
            </PlantContextProvider>
          }
        />

        <Route element={<ErrorPage errorMessage={t('errors.pageNotFound')} />} />
      </Routes>
    </DefaultShell>
  )
}
