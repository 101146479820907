import {SortDirection} from '@mui/material'
import {omit} from 'lodash'
import {UseQueryResult, useQuery} from 'react-query'

import {QueryKeyName, getFilteredActions} from '../../common/backend'
import {CLIENT_SIDE_FILTER_KEYS} from '../../containers/FilterBar'
import {ActionItem, PaginatedList, ActionItemKey, FilteredActionsParams} from '../../types'
import {useActionFilters} from '../urlParameters/useActionFilters'
import {usePlantId} from '../urlParameters/usePlantId'

type Params = {
  page: number
  rowsPerPage: number
  sortByKey?: ActionItemKey
  order: SortDirection
}

export const useFilteredActions = (params: Params): UseQueryResult<PaginatedList<ActionItem>> => {
  const plantId = usePlantId()
  const filters = omit(useActionFilters(), CLIENT_SIDE_FILTER_KEYS)
  const parameters: FilteredActionsParams = {
    ...params,
    ...filters,
    plantId
  }

  return useQuery(
    [QueryKeyName.GetActionItems, parameters],
    () => {
      return getFilteredActions(parameters)
    },
    {
      keepPreviousData: true
    }
  )
}
