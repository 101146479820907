import {CancelButton} from '@hconnect/uikit/src/lib2'
import {Download} from '@mui/icons-material'
import {Box, Dialog, DialogContent, DialogActions, Button, Link, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

export interface ImageFilePreviewProps {
  filename: string
  downloadHref?: string
  previewHref?: string
  width?: number | string
  height?: number | string
}

export const ImageFilePreview: React.FC<ImageFilePreviewProps> = ({
  filename,
  downloadHref,
  previewHref,
  width,
  height
}) => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <Box
        data-test-id={`image-preview-${filename}`}
        width={width}
        height={height}
        sx={[
          (theme) => ({
            backgroundColor: theme.palette.grey[100],
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            cursor: 'pointer'
          }),
          Boolean(downloadHref) && {
            backgroundImage: `url(${previewHref ?? downloadHref})`
          }
        ]}
        onClick={() => setOpen(true)}
      ></Box>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {backgroundColor: 'transparent'}
        }}
      >
        <DialogContent>
          <Box
            data-test-id={'https://example.com'}
            width={'100%'}
            height={'100%'}
            sx={{
              backgroundImage: `url(${downloadHref})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center center'
            }}
          ></Box>
        </DialogContent>
        <DialogActions sx={(theme) => ({backgroundColor: theme.palette.common.white})}>
          <Typography variant={'h3'} sx={{flex: 1}}>
            {filename}
          </Typography>
          <CancelButton onClick={() => setOpen(false)} data-test-id="image-preview-cancel-btn">
            {t('button.cancel')}
          </CancelButton>
          <Button
            startIcon={<Download />}
            variant={'contained'}
            color={'primary'}
            component={Link}
            href={downloadHref}
            download={filename}
            sx={{
              ml: 1.5
            }}
          >
            {t('button.download')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
