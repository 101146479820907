import {QuestionDto, TopicDto} from '@hconnect/common/types'

import {Topic, RequestFunc, Question} from '../../types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'
import {convertScoring} from './scoring'

export const convertQuestion = (dto: QuestionDto): Question => ({
  id: dto.id,
  difficulty: dto.difficulty,
  submission: dto.submission
})

export const convertTopic = (dto: TopicDto): Topic => ({
  ...convertScoring(dto.scoring),
  id: dto.topicId,
  name: dto.name,
  sectionId: dto.sectionId,
  responsible: dto.responsible,
  guidanceLink: dto.guidanceLink,
  questions: dto.questions?.map(convertQuestion)
})

const getTopicImpl: RequestFunc<{plantId: string; topicId: string}, Topic> = async ({
  plantId,
  topicId
}): Promise<Topic> => {
  const {data} = await dfApi.get<TopicDto>(`/pom-questionnaires/${plantId}/topics/${topicId}`)
  return convertTopic(data)
}
export const getTopic = loggingDecorator(getTopicImpl)

const assignTopicResponsibleImpl: RequestFunc<
  {plantId: string; topicId: string; userId: string},
  void
> = async ({plantId, topicId, userId}) => {
  await dfApi.post(`/pom-questionnaires/${plantId}/topics/${topicId}/responsible`, {userId})
}
export const assignTopicResponsible = loggingDecorator(assignTopicResponsibleImpl)
