import React from 'react'

export const QuestionsText: React.FC<{questions: string[]}> = ({questions}) => (
  <>
    {questions.map((question, index) => (
      <React.Fragment key={index}>
        {question}
        {index < questions.length - 1 ? <br /> : null}
      </React.Fragment>
    ))}
  </>
)
