import {UserDto} from '@hconnect/common/types'
import {SxProps, Theme} from '@mui/material'
import {debounce} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {SEARCH_DELAY_IN_MS} from '../../common/constants'
import {usePomUsers} from '../../hooks/api/usePomUsers'

import {ResponsiblePersonFormField} from './ResponsiblePersonFormField'

type ResponsiblePersonFieldProps = {
  value?: UserDto
  onChange: (user?: UserDto) => void
  required?: boolean
  error?: boolean
  sx?: SxProps<Theme>
}

export const ResponsiblePersonField: React.FC<ResponsiblePersonFieldProps> = ({
  value,
  onChange,
  required = false,
  error = false,
  sx
}) => {
  const {t} = useTranslation()
  const [namePattern, setNamePattern] = useState('')
  const {data, isFetching} = usePomUsers(namePattern, {
    enabled: !!namePattern
  })

  const changeName = debounce(setNamePattern, SEARCH_DELAY_IN_MS)

  return (
    <ResponsiblePersonFormField
      sx={sx}
      error={error}
      required={required}
      value={value}
      onChange={onChange}
      onInputChange={changeName}
      persons={data ?? []}
      loading={isFetching}
      label={t('actionDialog.assignee')}
    />
  )
}
