import {
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl
} from '@mui/material'
import {isString, identity} from 'lodash'
import React from 'react'

import {selectFieldOnBlueSx} from './filterStyles'

interface FilterMultiSelectProps<T> {
  value?: T[]
  onChange: (value?: T[]) => void
  options: ReadonlyArray<T>
  id: string
  label: string
  entryToString?: (entry: T) => string
}

export const FilterMultiSelect = <T extends string>({
  value,
  onChange,
  options,
  id,
  label,
  entryToString = identity
}: FilterMultiSelectProps<T>): React.ReactElement => {
  const labelId = `${id}-label`

  return (
    <FormControl sx={selectFieldOnBlueSx} variant={'filled'}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={`${id}-select`}
        multiple
        value={value}
        onChange={(event: SelectChangeEvent<T[]>) =>
          !isString(event.target.value) && onChange(event.target.value)
        }
        renderValue={(selected) => selected.map((sel) => entryToString(sel)).join(', ')}
        /**
         * For empty options list, Select component shows an unnecessary ul element, on UI which looks like
         * a white empty space.
         * This is a hack to avoid that.
         */
        inputProps={{readOnly: options.length < 1}}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value?.includes(option)} data-test-id={`${id}-checkbox-${option}`} />
            <ListItemText primary={entryToString(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
