import {AnswerStatus} from '@hconnect/common/types'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from 'react-query'

import {QueryKeyName, updateAction} from '../../common/backend'
import {allRelatedActionsDoneForQuestionOfAction} from '../../common/backend/actions'
import {ActionItem} from '../../types'
import {ActionStatus} from '../../types/backend.types'
import {usePlantId} from '../urlParameters/usePlantId'

export type EditActionResponse = {
  item: ActionItem
  showMoveQuestToDone?: boolean
}

export type EditActionData = {
  actionItem: ActionItem
  questionAnswer?: AnswerStatus
}

export const useEditAction = (onSuccess?: (data: EditActionResponse) => void) => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    async ({actionItem, questionAnswer}: EditActionData) => {
      await updateAction({plantId, actionItem})
      const response: EditActionResponse = {item: actionItem}
      if (actionItem.status === ActionStatus.Done && questionAnswer === AnswerStatus.Negative) {
        response.showMoveQuestToDone = await allRelatedActionsDoneForQuestionOfAction(actionItem)
      }
      return response
    },
    {
      onSuccess: (data: EditActionResponse) => {
        void client.invalidateQueries(QueryKeyName.GetActionItems)
        enqueueSnackbar(t('editAction.afterSubmit.success'), {
          variant: 'success'
        })
        onSuccess?.(data)
      },
      onError: () => {
        enqueueSnackbar(t('editAction.afterSubmit.error'), {variant: 'error'})
      }
    }
  )
}
