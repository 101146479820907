import {isString} from 'lodash'
import moment from 'moment'

import {CreateActionItem, ActionItem} from '../../types'
import {removeHTMLTags} from '../format'

import {validateItem} from './validator'
import {
  ActionValidator,
  ValidationError,
  ValidatorKeyMap,
  ItemValidationResult
} from './validator.types'

export const MAX_DESCRIPTION_CHARACTER_LIMIT = 3000

export const shouldNotBeEmpty: ActionValidator<Partial<CreateActionItem>> = (value) => {
  return value ? undefined : ValidationError.notEmpty
}

export const shouldNotBeEmptyAndHasMaxLength =
  (
    maxLength: number,
    textTransformFunction?: (string) => string
  ): ActionValidator<Partial<CreateActionItem>> =>
  (value) => {
    const item = isString(value) && textTransformFunction ? textTransformFunction(value) : value
    if (isString(item) && item.length > maxLength) {
      return ValidationError.maxSizeExceeded
    }
    return shouldNotBeEmpty(item)
  }

export const actionSourceValidator: ActionValidator<Partial<CreateActionItem>, 'source'> = (
  value
) => {
  return value ? undefined : ValidationError.notEmpty
}

export const shouldBeValidDate =
  (): ActionValidator<Partial<CreateActionItem>, 'dueDate'> => (d) => {
    if (!d) {
      return ValidationError.invalidDate
    }
    const date = moment(d)
    return date.isValid() ? undefined : ValidationError.invalidDate
  }

export const actionValidationConfig = (): ValidatorKeyMap<Partial<ActionItem>> => ({
  title: shouldNotBeEmpty,
  description: shouldNotBeEmptyAndHasMaxLength(MAX_DESCRIPTION_CHARACTER_LIMIT, removeHTMLTags),
  dueDate: shouldBeValidDate(),
  source: actionSourceValidator
})

export const validateActionItem = (
  actionItem: Partial<ActionItem>
): ItemValidationResult<Partial<ActionItem>> =>
  validateItem(
    {
      title: shouldNotBeEmpty,
      description: shouldNotBeEmptyAndHasMaxLength(MAX_DESCRIPTION_CHARACTER_LIMIT, removeHTMLTags),
      dueDate: shouldBeValidDate(),
      source: actionSourceValidator
    },
    actionItem
  )
