import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'
import {QUESTION_DIFFICULTIES} from '@hconnect/common/utils'
import {groupBy, isEmpty} from 'lodash'

import {QuestionAnswer, QuestionItem} from '../../types'
import {Question} from '../../types/backend.types'

export const ANSWER_STATES: AnswerStatus[] = [
  AnswerStatus.Positive,
  AnswerStatus.Negative,
  AnswerStatus.NotAvailable,
  AnswerStatus.Empty
]

const answerColorMap: Record<AnswerStatus, 'success' | 'error' | 'secondary'> = {
  [AnswerStatus.Positive]: 'success',
  [AnswerStatus.Negative]: 'error',
  [AnswerStatus.NotAvailable]: 'secondary',
  [AnswerStatus.Empty]: 'secondary'
}

export const colorByAnswer = (
  answer: AnswerStatus | undefined
): 'success' | 'error' | 'secondary' | undefined => answer && answerColorMap[answer]

export const ANSWER_STATUS_LABEL_COLOR_MAP: Record<AnswerStatus, string> = {
  [AnswerStatus.Positive]: 'success.light',
  [AnswerStatus.Negative]: 'error.light',
  [AnswerStatus.NotAvailable]: 'text.secondary',
  [AnswerStatus.Empty]: 'text.primary'
}

const answerTranslationKeyMap: Record<AnswerStatus, string> = {
  [AnswerStatus.Positive]: 'yes',
  [AnswerStatus.Negative]: 'no',
  [AnswerStatus.NotAvailable]: 'notApplicable',
  [AnswerStatus.Empty]: 'noAnswer'
}

export const translationAriaKeyByAnswer = (answer: AnswerStatus | undefined): string =>
  `ariaAnswer.${answer ? answerTranslationKeyMap[answer] : 'noAnswer'}`

export const mapToQuestionItems = (questions: Question[]): QuestionItem[] =>
  questions.map((quest) => ({
    id: quest.id,
    difficulty: quest.difficulty,
    answer: quest.submission?.answer,
    questions: quest.subQuestions,
    attachments: quest.attachments,
    guidanceLink: quest.guidanceLink
  }))

export const questionsToQuestionItemsMap = (
  questions: Question[]
): Record<QuestionDifficulty, QuestionItem[]> => {
  const mappedQues = mapToQuestionItems(questions)
  const respBasic = mappedQues.filter((item) => item.difficulty === QuestionDifficulty.Basic)
  const respAdvanced = mappedQues.filter((item) => item.difficulty === QuestionDifficulty.Advanced)
  const respExcellence = mappedQues.filter(
    (item) => item.difficulty === QuestionDifficulty.Excellence
  )
  return {
    [QuestionDifficulty.Basic]: respBasic,
    [QuestionDifficulty.Advanced]: respAdvanced,
    [QuestionDifficulty.Excellence]: respExcellence
  }
}

export const answerPositiveOrNa = (answer?: AnswerStatus): boolean =>
  answer === AnswerStatus.Positive || answer === AnswerStatus.NotAvailable

const questionPositiveOrNa = (question: Question): boolean =>
  answerPositiveOrNa(question.submission?.answer)

const areQuestionsComplete = (questions: Question[]): boolean =>
  !isEmpty(questions) && questions.every(questionPositiveOrNa)

export const completedDifficultyLevels = (questions: Question[]): QuestionDifficulty[] => {
  const grouped = groupBy(questions, (question) => question.difficulty)
  return QUESTION_DIFFICULTIES.filter((difficulty) => areQuestionsComplete(grouped[difficulty]))
}

export const isDifficultyLevelComplete = (
  questions: Question[],
  difficulty: QuestionDifficulty
): boolean =>
  areQuestionsComplete(questions.filter((question) => question.difficulty === difficulty))

const getLowerDifficultyLevels = (difficulty: QuestionDifficulty): QuestionDifficulty[] => {
  const idx = QUESTION_DIFFICULTIES.findIndex((diff) => diff === difficulty)
  if (idx < 0) {
    return []
  }
  return QUESTION_DIFFICULTIES.slice(0, idx)
}

// check if answer would complete the category
export const doesAnswerCompleteCategory = (
  answer: QuestionAnswer,
  questions: Question[]
): boolean => {
  if (!answerPositiveOrNa(answer.answer)) {
    return false
  }

  // find answer in questions
  const relatedQuestion = questions.find((question) => question.id === answer.questionId)

  // if the answer was positive or n/a before the current answer does not change the state
  if (!relatedQuestion || answerPositiveOrNa(relatedQuestion.submission?.answer)) {
    return false
  }

  const difficulty = relatedQuestion.difficulty
  const lowerDifficulties = getLowerDifficultyLevels(difficulty)

  if (lowerDifficulties.some((diff) => !isDifficultyLevelComplete(questions, diff))) {
    return false
  }

  const otherQuestionsInCategory = questions.filter(
    (question) => question.difficulty === difficulty && question.id !== relatedQuestion.id
  )

  return (
    isEmpty(otherQuestionsInCategory) ||
    isDifficultyLevelComplete(otherQuestionsInCategory, difficulty)
  )
}
