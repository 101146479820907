import {AnswerStatus} from '@hconnect/common/types'
import {Done, Clear, IndeterminateCheckBoxOutlined} from '@mui/icons-material'
import React from 'react'

const answerIconMap: Record<AnswerStatus, React.ReactNode> = {
  [AnswerStatus.Positive]: <Done />,
  [AnswerStatus.Negative]: <Clear />,
  [AnswerStatus.NotAvailable]: <IndeterminateCheckBoxOutlined />,
  [AnswerStatus.Empty]: <></>
}

export const AnswerIcon: React.FC<{answer?: AnswerStatus}> = ({answer}) => (
  <>{answer && answerIconMap[answer]}</>
)
