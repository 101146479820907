import {DeletionModal, EditDeleteMoreButton} from '@hconnect/uikit/src/lib2'
import {isString} from 'lodash'
import React, {useState, useRef, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useDeleteAction} from '../../hooks/api/useDeleteAction'
import {useActionId} from '../../hooks/urlParameters/useSearchParameter'
import {ActionItem} from '../../types'
import {EditActionDialog} from '../actionDialog/EditActionDialog'

interface ActionsMoreButtonProps {
  item: ActionItem
  moreButtonTestId?: string
  enableEditMode?: (action: ActionItem) => void
}

export const ActionsMoreButton: React.FC<ActionsMoreButtonProps> = ({
  item,
  moreButtonTestId,
  enableEditMode
}) => {
  const {t} = useTranslation()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const actionIdToDelete = useRef<string>()
  const [, setActionId] = useActionId()
  const {mutate: deleteAction, isLoading: deleting} = useDeleteAction()

  const onDeleteClicked = useCallback((actionItem: ActionItem) => {
    actionIdToDelete.current = actionItem.id
    setDeleteDialogOpen(true)
  }, [])

  const onDeleteConfirmed = useCallback(() => {
    if (isString(actionIdToDelete.current)) {
      deleteAction(actionIdToDelete.current)
    }
    setDeleteDialogOpen(false)
    setActionId(undefined)
  }, [deleteAction, setActionId])

  const onDeleteCancelled = useCallback(() => {
    setDeleteDialogOpen(false)
    actionIdToDelete.current = undefined
  }, [])

  return (
    <>
      <EditDeleteMoreButton
        loading={deleting}
        item={item}
        onEdit={enableEditMode ? enableEditMode : () => setEditDialogOpen(true)}
        onDelete={onDeleteClicked}
        data-test-id={moreButtonTestId}
      />
      <DeletionModal
        open={deleteDialogOpen}
        message={t('actions.deletionDialog.message')}
        cancelButtonCaption={t('button.cancel')}
        cancelButtonTestId={'action-delete-cancel-btn'}
        confirmButtonTestId={'action-delete-confirm-btn'}
        confirmButtonCaption={t('button.delete')}
        onConfirm={onDeleteConfirmed}
        onCancel={onDeleteCancelled}
      />
      <EditActionDialog
        open={editDialogOpen}
        handleOpen={(open) => setEditDialogOpen(open)}
        actionItem={item}
      />
    </>
  )
}
