import {UserDto} from '@hconnect/common/types'
import {Autocomplete, TextField, Paper, SxProps, Theme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface ResponsiblePersonFieldProps {
  value?: UserDto
  onChange: (person?: UserDto) => void
  onInputChange: (name: string) => void
  persons: UserDto[]
  label?: string
  loading?: boolean
  required?: boolean
  error?: boolean
  sx?: SxProps<Theme>
}

export const ResponsiblePersonFormField: React.FC<ResponsiblePersonFieldProps> = ({
  onChange,
  onInputChange,
  value,
  persons,
  label,
  loading = false,
  required = false,
  error = false,
  sx
}) => {
  const {t} = useTranslation()

  return (
    <Autocomplete
      data-test-id={'responsible-person-field'}
      loading={loading}
      value={value ?? null}
      options={persons}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      getOptionLabel={(option) => option.name}
      loadingText={t('responsiblePerson.loading')}
      clearText={t('button.clear')}
      closeText={t('button.close')}
      openText={t('button.open')}
      noOptionsText={t('responsiblePerson.noPersons')}
      onInputChange={(event, value) => onInputChange(value)}
      onChange={(event, value) => {
        onChange(value ?? undefined)
      }}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          required={required}
          variant={'filled'}
          fullWidth
          label={label ?? t('responsiblePerson.fieldLabel')}
        />
      )}
      PaperComponent={(props) => <Paper {...props} elevation={8} />}
    ></Autocomplete>
  )
}
