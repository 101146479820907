import {isEmpty} from 'lodash'

import {ValidatorKeyMap, ValidationResult, ItemValidationResult} from './validator.types'

export const validate = <T>(
  validatorKeyMap: ValidatorKeyMap<T>,
  key: keyof T,
  item: T
): ValidationResult => {
  const validator = validatorKeyMap[key]
  return validator ? validator(item[key], item) : undefined
}

export const validateItem = <T>(
  validatorKeyMap: ValidatorKeyMap<T>,
  item: T
): ItemValidationResult<T> => {
  const validationError: Partial<Record<keyof T, ValidationResult>> = {}

  Object.keys(validatorKeyMap).forEach((key) => {
    const itemKey = key as keyof T
    const validator = validatorKeyMap[itemKey]
    const failed: ValidationResult = validator?.(item[itemKey], item)
    if (failed) {
      validationError[key] = failed
    }
  })

  return validationError
}

export const isItemValid = <T>(validatorKeyMap: ValidatorKeyMap<T>, item: T): boolean =>
  isEmpty(validateItem(validatorKeyMap, item))
