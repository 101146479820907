import {SxProps, Theme} from '@mui/material'
import {isEmpty} from 'lodash'

import {CollapsableRowItem} from './CollapsableRow'

export const itemsExpandable = (items: CollapsableRowItem[]) => {
  return !items.every(({subItems}) => isEmpty(subItems))
}

export const expandableTableHeaderCellSx = (expandable: boolean): SxProps<Theme> => ({
  ml: expandable ? 2.5 : 0
})
