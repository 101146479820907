import {parseIsoDateStringToDate} from '@hconnect/common/dates/ISO8601Helpers'
import {orderBy} from 'lodash'

import {PomComment, RequestFunc} from '../../types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'
import {CommentDto} from './types'

const convertCommentFromDto = ({
  commentId,
  createdOn,
  updatedOn,
  createdBy,
  updatedBy,
  status,
  commentsCategoryInstanceId,
  value
}: CommentDto): PomComment => ({
  id: commentId,
  createdOn: parseIsoDateStringToDate(createdOn),
  updatedOn: updatedOn ? parseIsoDateStringToDate(updatedOn) : undefined,
  createdBy,
  updatedBy,
  referenceId: commentsCategoryInstanceId,
  status,
  value
})

const COMMENTS_CATEGORY = 'POMQuestions'

const addCommentImpl: RequestFunc<
  {
    plantId: string
    questionId: string
    comment: string
  },
  PomComment
> = async ({plantId, questionId, comment}) => {
  const {data} = await dfApi.post<CommentDto>(
    `comments/${plantId}/${COMMENTS_CATEGORY}/${questionId}`,
    {
      value: comment
    }
  )
  return convertCommentFromDto(data)
}
export const addComment = loggingDecorator(addCommentImpl)

const getCommentsImpl: RequestFunc<
  {
    plantId: string
    questionId: string
  },
  PomComment[]
> = async ({plantId, questionId}) => {
  const {data} = await dfApi.get<CommentDto[]>(
    `/comments/${plantId}/${COMMENTS_CATEGORY}?commentCategoryInstanceIds=${questionId}`
  )
  return orderBy(data.map(convertCommentFromDto), ['createdOn'], ['desc'])
}

export const getComments = loggingDecorator(getCommentsImpl)

const updateCommentImpl: RequestFunc<
  {
    plantId: string
    comment: PomComment
  },
  void
> = async ({plantId, comment}) => {
  const {id, referenceId, value} = comment
  await dfApi.put(`comments/${plantId}/${COMMENTS_CATEGORY}/${referenceId}/${id}`, {
    value
  })
}

export const updateComment = loggingDecorator(updateCommentImpl)

const deleteCommentImpl: RequestFunc<
  {
    plantId: string
    comment: PomComment
  },
  void
> = async ({plantId, comment}) => {
  const {id, referenceId} = comment
  await dfApi.delete(`comments/${plantId}/${COMMENTS_CATEGORY}/${referenceId}/${id}`)
}

export const deleteComment = loggingDecorator(deleteCommentImpl)
