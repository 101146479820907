import {Loader, ErrorMessage, CardBox} from '@hconnect/uikit/src/lib2'
import React from 'react'

interface DataFetchingPlaceholderProps {
  isLoading: boolean
  errorMessage: string
  loader?: React.ReactNode
  height?: number | string
}

export const DataFetchingPlaceholder: React.FC<DataFetchingPlaceholderProps> = ({
  isLoading,
  errorMessage,
  loader = <Loader />,
  height = 128
}) => (
  <CardBox sx={{display: 'flex', height, alignItems: 'center', justifyContent: 'center'}}>
    {isLoading ? loader : <ErrorMessage>{errorMessage}</ErrorMessage>}
  </CardBox>
)
