import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'
import React, {createContext, useContext, useState, FC, ReactNode} from 'react'

type QuestionState = {
  activeAnswerStatus?: AnswerStatus
  activeDifficulty: QuestionDifficulty
}

type QuestionContext = [
  QuestionState,
  {
    setActiveAnswerStatus: React.Dispatch<React.SetStateAction<AnswerStatus | undefined>>
    setActiveDifficulty: React.Dispatch<React.SetStateAction<QuestionDifficulty>>
  }
]

const INITIAL_DATA: QuestionContext = [
  {
    activeDifficulty: QuestionDifficulty.Basic
  },
  {
    setActiveAnswerStatus: () => {
      // init
    },
    setActiveDifficulty: () => {
      // init
    }
  }
]

const Context = createContext<QuestionContext>(INITIAL_DATA)

export const QuestionStateProvider: FC<{children: ReactNode; data: QuestionState}> = ({
  children,
  data
}) => {
  const [activeAnswerStatus, setActiveAnswerStatus] = useState<AnswerStatus | undefined>(
    data.activeAnswerStatus
  )
  const [activeDifficulty, setActiveDifficulty] = useState<QuestionDifficulty>(
    data.activeDifficulty
  )

  const value: QuestionContext = [
    {
      activeAnswerStatus,
      activeDifficulty
    },
    {
      setActiveAnswerStatus,
      setActiveDifficulty
    }
  ]

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useQuestionState = () => {
  const ctx = useContext(Context)
  if (ctx === undefined) {
    throw new Error('useQuestionState used outside of QuestionStateProvider')
  } else {
    return ctx
  }
}
