import {MenuItem, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ActionStatusType, ACTION_STATUS_LIST} from '../../types/backend.types'

type Props = {
  onChange: (data: ActionStatusType) => void
  value?: ActionStatusType
  error?: boolean
}

export const StatusField: React.FC<Props> = ({onChange, error, value = 'pending'}) => {
  const {t} = useTranslation()

  return (
    <TextField
      fullWidth
      variant="filled"
      label={t('actionDialog.status')}
      select
      data-test-id="action-status"
      value={value}
      error={error}
      onChange={(event) => {
        onChange(event.target.value as ActionStatusType)
      }}
    >
      {ACTION_STATUS_LIST.map((status) => (
        <MenuItem
          data-test-id={`action-status-dropdown-item-${status}`}
          key={status}
          value={status}
        >
          {t(`actions.${status}`)}
        </MenuItem>
      ))}
    </TextField>
  )
}
