import {QuestionDifficulty} from '@hconnect/common/types'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import RewardSvg from './reward.svg'

type Props = {
  open: boolean
  sectionCompleted: QuestionDifficulty
  onOpenChange: (open: boolean) => void
}

export const LevelCompletion: React.FC<Props> = (props) => {
  const {t} = useTranslation()
  const {sectionCompleted, open, onOpenChange} = props

  return (
    <Dialog open={open} data-test-id="level-completion-dialog">
      <DialogTitle>
        {t(`questionDifficulty.${sectionCompleted}`)} {t('levelReward.title')}
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight={500}>
          {t('levelReward.description', {section: sectionCompleted})}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Box
            component="img"
            src={RewardSvg}
            alt="Reward-Img"
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                width: '340px'
              }
            })}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center'}}>
        <Button
          variant="outlined"
          startIcon={<CloseIcon />}
          color={'secondary'}
          onClick={() => onOpenChange(false)}
          data-test-id="close-btn"
        >
          {t('levelReward.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
