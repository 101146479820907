import {useSorting} from '@hconnect/common/hooks/useSorting'
import {QuestionDifficulty} from '@hconnect/common/types'
import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  SortDirection,
  Theme
} from '@mui/material'
import {SxProps} from '@mui/system'
import {orderBy, isEqual} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {DASHBOARD} from '../../common/routing'
import {PlantTableEntry} from '../../types'

import {
  renderPlantTableCell,
  PLANT_COLUMN_IDS,
  PlantTableColumnKey,
  getScoreCellStyle,
  SIDE_PROGRESS_COLUMN_PADDING,
  MIDDLE_PROGRESS_COLUMN_PADDING
} from './plantTableUtils'

interface PlantTableProps {
  plants: PlantTableEntry[]
}

interface PlantTableHeaderCellProps {
  columnKey: PlantTableColumnKey
  orderByKey?: PlantTableColumnKey
  sortDir: SortDirection
  onSortClick: (key: PlantTableColumnKey) => void
  sx?: SxProps<Theme>
  children?: React.ReactNode
}

const PlantTableHeaderCell = ({
  columnKey,
  orderByKey,
  sortDir,
  onSortClick,
  children,
  sx
}: PlantTableHeaderCellProps) => (
  <TableCell sortDirection={orderByKey === columnKey && sortDir} sx={sx}>
    <TableSortLabel
      active={sortDir && orderByKey === columnKey}
      direction={orderByKey === columnKey && sortDir ? sortDir : undefined}
      onClick={() => onSortClick(columnKey)}
    >
      {children}
    </TableSortLabel>
  </TableCell>
)

const sortPlants = (
  plants: PlantTableEntry[],
  orderByKey: PlantTableColumnKey,
  sortDir: SortDirection
): PlantTableEntry[] => {
  if (
    orderByKey === QuestionDifficulty.Advanced ||
    orderByKey === QuestionDifficulty.Basic ||
    orderByKey === QuestionDifficulty.Excellence
  ) {
    plants.sort((a: PlantTableEntry, b: PlantTableEntry) => {
      if (
        a.difficultySummaries[orderByKey].answeredPositively <
        b.difficultySummaries[orderByKey].answeredPositively
      ) {
        return sortDir === 'asc' ? -1 : 1
      }

      if (
        a.difficultySummaries[orderByKey].answeredPositively >
        b.difficultySummaries[orderByKey].answeredPositively
      ) {
        return sortDir === 'asc' ? 1 : -1
      }
      return 0
    })

    return plants
  }
  return orderBy(plants, [orderByKey], [sortDir])
}

const PlantTableComp: React.FC<PlantTableProps> = ({plants}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const {sortedList, onSortClick, orderByKey, sortDir} = useSorting<
    PlantTableEntry,
    PlantTableColumnKey
  >({
    initialSortDir: false,
    initialOrderByKey: 'name',
    data: plants,
    sort: sortPlants
  })

  return (
    <TableContainer data-test-id="plants-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <PlantTableHeaderCell
              columnKey={'name'}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
            >
              {t('dashboardTable.location')}
            </PlantTableHeaderCell>
            <PlantTableHeaderCell
              columnKey={QuestionDifficulty.Basic}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
              sx={{py: 1.125, pr: SIDE_PROGRESS_COLUMN_PADDING}}
            >
              {t('headings.basic')}
            </PlantTableHeaderCell>
            <PlantTableHeaderCell
              columnKey={QuestionDifficulty.Advanced}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
              sx={{py: 1.125, px: MIDDLE_PROGRESS_COLUMN_PADDING}}
            >
              {t('headings.advanced')}
            </PlantTableHeaderCell>
            <PlantTableHeaderCell
              columnKey={QuestionDifficulty.Excellence}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
              sx={{py: 1.125, pl: SIDE_PROGRESS_COLUMN_PADDING}}
            >
              {t('headings.excellence')}
            </PlantTableHeaderCell>
            <PlantTableHeaderCell
              columnKey={'responsible'}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
            >
              {t('dashboardTable.responsible')}
            </PlantTableHeaderCell>
            <PlantTableHeaderCell
              columnKey={'lastUpdate'}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
            >
              {t('dashboardTable.lastUpdate')}
            </PlantTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedList.map((plant, index) => (
            <TableRow
              data-test-id={`plant-table-row-${plant.id}`}
              key={plant.id}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: customThemeConstants().palette.primaryBackgroundColor
                }
              }}
              onClick={() => {
                navigate(generatePath(DASHBOARD, {plantId: plant.id}))
              }}
            >
              {PLANT_COLUMN_IDS.map((columnId) => (
                <TableCell key={columnId} sx={getScoreCellStyle(columnId)}>
                  {renderPlantTableCell(plant, index, columnId)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const PlantTable = React.memo(PlantTableComp, isEqual)
