import {Tooltip, TooltipProps, Typography, TypographyProps} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {SxProps} from '@mui/system'
import React, {memo, useState, ReactNode, useRef, useEffect} from 'react'

import {mergeSx} from '../../utils'

type TextEllipsisProps = TypographyProps & {
  title: NonNullable<ReactNode>
  tooltipProps?: TooltipProps
  sx?: SxProps<Theme>
}

export const TextEllipsis = memo<TextEllipsisProps>(({title, tooltipProps, sx, ...rest}) => {
  const ref = useRef<HTMLParagraphElement>(null)
  const [isTooltipNeeded, setIsTooltipNeeded] = useState<boolean>(false)
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)

  useEffect(() => {
    const textElement: HTMLElement | null | undefined = ref.current
    if (textElement) {
      const hasOverflow = textElement.scrollWidth > textElement.offsetWidth
      setIsTooltipNeeded(hasOverflow)
    }
  }, [])

  const onVisibleChange = () => {
    const isVisible = isTooltipNeeded && !isTooltipVisible
    setIsTooltipVisible(isVisible)
  }

  const titleBox = (
    <Typography
      ref={ref}
      sx={mergeSx(
        {
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          margin: 0
        },
        sx
      )}
      {...rest}
    >
      {title}
    </Typography>
  )

  return isTooltipNeeded ? (
    <Tooltip
      title={title}
      open={isTooltipVisible}
      onOpen={onVisibleChange}
      onClose={onVisibleChange}
      {...tooltipProps}
    >
      {titleBox}
    </Tooltip>
  ) : (
    titleBox
  )
})
