import {Add} from '@mui/icons-material'
import {Box, IconButton, Typography, IconButtonProps} from '@mui/material'
import React from 'react'

export const DefaultAddButton: React.FC<IconButtonProps> = (props) => (
  <IconButton component={'span'} color={'primary'} {...props}>
    <Add />
  </IconButton>
)

interface AddInformationHeaderProps {
  title: string
  children?: React.ReactNode
}

export const AddInformationHeader = ({title, children}: AddInformationHeaderProps) => (
  <Box display="flex" justifyContent="space-between" alignItems={'center'}>
    <Typography variant="button">{title}</Typography>
    {children}
  </Box>
)
