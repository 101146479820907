import {toggleSortDir} from '@hconnect/common/utils/sorting'
import {
  Table,
  TableContainer,
  TableBody,
  TablePagination,
  SortDirection,
  Breakpoint
} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {isActionOverdue} from '../../common/domain/actions'
import {sanitizePageIndex} from '../../common/paginationUtils'
import {useWidth} from '../../hooks/useWidth'
import {ActionItem, ActionItemKey, PaginatedList} from '../../types'

import {ActionRow} from './ActionRow'
import {ActionsTableHeader} from './ActionsTableHeader'

export const ACTION_TABLE_COLUMNS: ActionItemKey[] = [
  'title',
  'source',
  'section',
  'topic',
  'assignee',
  'dueDate',
  'status'
]

const VISIBLE_COLUMNS: Record<Breakpoint, ActionItemKey[]> = {
  xs: ['title', 'status'],
  sm: ['title', 'source', 'assignee', 'status'],
  md: ['title', 'source', 'assignee', 'dueDate', 'status'],
  lg: ACTION_TABLE_COLUMNS,
  xl: ACTION_TABLE_COLUMNS
}

export const withSideCard = new Map<Breakpoint, Breakpoint>([
  ['sm', 'xs'],
  ['md', 'sm'],
  ['lg', 'md'],
  ['xl', 'lg']
])

export const noSideCard = new Map<Breakpoint, Breakpoint>([
  ['xs', 'xs'],
  ['sm', 'sm'],
  ['md', 'md'],
  ['lg', 'lg'],
  ['xl', 'xl']
])

type ActionsPaginatedTableProps = {
  onRowClick?: (action: ActionItem) => void
  onPageIndexChange: (val?: number) => void
  pageIndex: number
  onRowsPerPageChange: (val?: number) => void
  rowsPerPage: number
  setSortKey: (val?: keyof ActionItem | 'name') => void
  sortKey?: keyof ActionItem | 'name'
  onSortDirChange: (val?: SortDirection) => void
  sortDir: SortDirection
  data?: PaginatedList<ActionItem>
  isSideCardOpen?: boolean
}

export const ActionsPaginatedTable: React.FC<ActionsPaginatedTableProps> = ({
  onRowClick,
  pageIndex,
  onPageIndexChange,
  onRowsPerPageChange,
  rowsPerPage,
  setSortKey,
  sortKey,
  onSortDirChange,
  sortDir,
  data,
  isSideCardOpen
}) => {
  const {t} = useTranslation()

  const breakPoint = useWidth()
  const tableSize = (isSideCardOpen ? withSideCard : noSideCard).get(breakPoint) ?? 'sm'

  const actions = data?.list ?? []
  const itemCount = data?.total ?? 0
  const realPage = sanitizePageIndex({pageIndex, rowsPerPage, itemCount})

  useEffect(() => {
    if (data && pageIndex !== realPage) {
      onPageIndexChange(realPage)
    }
  }, [data, pageIndex, realPage, onPageIndexChange])

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    onPageIndexChange(page)
  }

  const onSortClick = (clickedColumnKey: ActionItemKey) => {
    const newSortDir = toggleSortDir(sortDir, clickedColumnKey, sortKey)
    onSortDirChange(newSortDir)
    setSortKey(clickedColumnKey)
  }

  const now = new Date()

  return (
    <TableContainer>
      <Table>
        <ActionsTableHeader
          sortDir={sortDir}
          onSortClick={onSortClick}
          orderByKey={sortKey}
          visibleColumns={VISIBLE_COLUMNS[tableSize]}
        />
        <TableBody>
          {actions.map((action) => (
            <ActionRow
              key={action.id}
              action={action}
              isOverdue={isActionOverdue(action, now)}
              visibleColumns={VISIBLE_COLUMNS[tableSize]}
              onClick={onRowClick}
            />
          ))}
        </TableBody>
      </Table>
      {actions.length < itemCount && (
        <TablePagination
          data-test-id={'actions-paginated-table-pagination'}
          sx={{display: 'flex', justifyContent: 'center'}}
          component={'div'}
          labelDisplayedRows={({from, to, count}) =>
            t('pagination.labelDisplayedRows', {from, to, count})
          }
          getItemAriaLabel={(type) =>
            t('pagination.goToPage', {pageType: t(`pagination.pageType.${type}`)})
          }
          labelRowsPerPage={t('pagination.labelRowsPerPage')}
          count={itemCount}
          page={realPage}
          rowsPerPage={rowsPerPage}
          onPageChange={onPageChange}
          onRowsPerPageChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            onRowsPerPageChange(+event.target.value)
          }}
        />
      )}
    </TableContainer>
  )
}
