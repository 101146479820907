import {
  AnswerStatus,
  Attachment,
  DifficultySummaries,
  QuestionDifficulty
} from '@hconnect/common/types'

import {LocationType, WithId, WithName} from './domain.types'

export type SearchParamGetSet<T> = [val: T | undefined, setVal: (val?: T) => void]
export type SearchParamGetSetWithDefault<T> = [val: T, setVal: (val?: T) => void]

export interface QuestionItem {
  id: string
  difficulty: QuestionDifficulty
  answer?: AnswerStatus
  questions: string[]
  attachments: Attachment[]
  guidanceLink?: string
}

export interface WithTestId {
  'data-test-id'?: string
}

export interface LocationBaseTableEntry {
  id: string
  name: string
  countryCode?: string
  difficultySummaries: DifficultySummaries
  responsible: string
  lastUpdate?: Date
}

export interface LocationTreeTableEntry extends LocationBaseTableEntry {
  id: string
  type: LocationType
  name: string
  countryCode?: string
  difficultySummaries: DifficultySummaries
  responsible: string
  lastUpdate?: Date
  subItems?: LocationTreeTableEntry[]
}

export interface PlantTableEntry {
  id: string
  name: string
  countryCode?: string
  difficultySummaries: DifficultySummaries
  responsible: string
  lastUpdate?: Date
}

export type Category = {
  difficultySummaries: DifficultySummaries
} & WithId &
  WithName
