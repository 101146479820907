import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'

import {Question, Section, Topic} from '../types'

export type SubmissionSummary = Record<AnswerStatus, number> & {
  difficulty: QuestionDifficulty
}

export const countSubmissions = (
  items: {questions?: Question[]}[],
  difficulty: QuestionDifficulty
): SubmissionSummary =>
  items.reduce(
    (result, item) => {
      item.questions?.forEach((question) => {
        if (question.difficulty === difficulty) {
          const submission = question.submission?.answer || AnswerStatus.Empty
          result[submission]++
        }
      })
      return result
    },
    {
      [AnswerStatus.Positive]: 0,
      [AnswerStatus.Negative]: 0,
      [AnswerStatus.NotAvailable]: 0,
      [AnswerStatus.Empty]: 0,
      difficulty
    }
  )

export const countAllQuestionsSubmissions = (
  questions: Question[],
  difficulty: QuestionDifficulty
): SubmissionSummary => {
  return countSubmissions([{questions}], difficulty)
}

export const countAllTopicsSubmissions = (
  topics: Topic[],
  difficulty: QuestionDifficulty
): SubmissionSummary => {
  const questions = topics.flatMap((topic) => topic.questions || [])
  return countSubmissions([{questions}], difficulty)
}

export const countAllSubmissions = (
  sections: Section[],
  difficulty: QuestionDifficulty
): SubmissionSummary => {
  const questions = sections.flatMap((section) =>
    section.topics.flatMap((topic) => topic.questions || [])
  )
  return countSubmissions([{questions}], difficulty)
}
