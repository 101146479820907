import {Error403Page} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useAuthContext} from '../providers/AuthProvider'

export const PomError403Page: React.FC = () => {
  const {t} = useTranslation()
  const {logout} = useAuthContext()

  return (
    <Error403Page
      caption={t('errors.accessDenied')}
      description={t('errors.accessDeniedDescription')}
      onLogout={logout}
      logoutCaption={t('logout')}
    />
  )
}
