import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from 'react-query'

import {QueryKeyName, createAction} from '../../common/backend'
import {ActionItem, CreateActionItem} from '../../types'
import {usePlantId} from '../urlParameters/usePlantId'

export const useCreateAction = (onSuccess?: (item: ActionItem) => void) => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation((actionItem: CreateActionItem) => createAction({plantId, actionItem}), {
    onSuccess: (item: ActionItem) => {
      void client.invalidateQueries(QueryKeyName.GetActionItems)
      enqueueSnackbar(t('addAction.afterSubmit.success'), {
        variant: 'success'
      })
      onSuccess?.(item)
    },
    onError: () => {
      enqueueSnackbar(t('addAction.afterSubmit.error'), {variant: 'error'})
    }
  })
}
