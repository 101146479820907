import {QuestionDifficulty, Scoring} from '@hconnect/common/types'

import {WithScoring} from '../../types'

const DEFAULT_SCORING: Scoring = {
  score: 0,
  completion: 0,
  difficultySummaries: {
    [QuestionDifficulty.Basic]: {
      answeredPositively: 0,
      questionCount: 0,
      positivePercent: 0
    },
    [QuestionDifficulty.Advanced]: {
      answeredPositively: 0,
      questionCount: 0,
      positivePercent: 0
    },
    [QuestionDifficulty.Excellence]: {
      answeredPositively: 0,
      questionCount: 0,
      positivePercent: 0
    }
  }
} as const

export const convertScoring = (scoring: Scoring = DEFAULT_SCORING): WithScoring => scoring
