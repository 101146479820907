import {CancelButton} from '@hconnect/uikit/src/lib2'
import {Check} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {isEmpty, isEqual} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ItemValidationResult} from '../../common/validator/validator.types'
import {CreateActionItem, ActionItem} from '../../types'
import {ActionForm} from '../actions/ActionForm'

type Props<T> = {
  open: boolean
  handleOpen: (open: boolean) => void
  actionItem: Partial<T>
  onSave: (item: Partial<T>) => void
  onValidate: (item: Partial<T>) => ItemValidationResult<T>
  saving?: boolean
  title: string
}

export const ActionDialog = <T extends ActionItem | CreateActionItem>({
  open,
  handleOpen,
  actionItem,
  onSave,
  onValidate,
  saving = false,
  title
}: Props<T>) => {
  const {t} = useTranslation()
  const [formValues, setFormValues] = React.useState<Partial<T>>(actionItem)

  const validationErrors = useMemo(() => onValidate(formValues), [formValues, onValidate])
  const isFormValid = isEmpty(validationErrors)

  const onEnter = () => {
    setFormValues(actionItem)
  }

  const handleClose = () => {
    handleOpen(false)
  }

  const handleSubmit = () => {
    onSave(formValues)
  }

  const handleInputChange = (key: keyof T, value) => {
    setFormValues((values) => ({...values, [key]: value}))
  }

  const saveDisabled = isEqual(actionItem, formValues)

  return (
    <Dialog
      TransitionProps={{
        onEnter
      }}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      data-test-id="action-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ActionForm
          formValues={formValues}
          validationErrors={validationErrors}
          handleInputChange={handleInputChange}
          actionItem={actionItem}
        />
      </DialogContent>

      <DialogActions sx={{padding: 3}}>
        <CancelButton onClick={handleClose} data-test-id="cancel-action">
          {t('button.cancel')}
        </CancelButton>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          disabled={!isFormValid || saveDisabled}
          startIcon={<Check />}
          data-test-id="save-action"
          loading={saving}
          loadingPosition={'start'}
        >
          {t('button.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
