import {Box, Typography, Theme} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

type ActionConnectedToSectionProps = {
  value: string
}

export const ActionConnectedToSection: FC<ActionConnectedToSectionProps> = ({value}) => {
  const {t} = useTranslation()

  return (
    <Box data-test-id="action-connected-to">
      <Typography
        sx={(theme: Theme) => ({
          color: theme.palette.grey[600]
        })}
        variant="caption"
      >
        {t('actionDialog.connectedTo')}
      </Typography>
      <Typography sx={{fontWeight: 500}}>{value}</Typography>
    </Box>
  )
}
