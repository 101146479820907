import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'
import React, {useCallback, useMemo} from 'react'
import {generatePath, NavigateFunction, useNavigate} from 'react-router-dom'

import {PLANT_SUB_SECTION} from '../common/routing'
import {CompletionCard} from '../components/completion/CompletionCard'
import {PlantSideCard} from '../components/plantSideCard'
import {QuestionListSideCard} from '../components/questionListSideCard'
import {useSectionActions} from '../hooks/api/useSectionActions'
import {usePlantId} from '../hooks/urlParameters/usePlantId'
import {useStringSearchParameter} from '../hooks/urlParameters/useSearchParameter'
import {useQuestionState} from '../hooks/useQuestionState'
import {Section, Topic} from '../types'
import {Question} from '../types/backend.types'
import {countAllTopicsSubmissions} from '../utils'

import {SectionTopContainer} from './SectionTopContainer'

interface SectionContainerProps {
  section: Section
}

export const SectionContainer: React.FC<SectionContainerProps> = ({section}) => {
  const topics: Topic[] = section.topics
  const plantId = usePlantId()
  const navigate: NavigateFunction = useNavigate()
  const {data: actions, status} = useSectionActions(section.id)
  const [, setQuestionId] = useStringSearchParameter('selectedQuestionId')

  const [{activeAnswerStatus, activeDifficulty}, {setActiveDifficulty, setActiveAnswerStatus}] =
    useQuestionState()

  const onScoreSectionClick = useCallback(
    (status?: AnswerStatus) => {
      setActiveAnswerStatus(status)
      setQuestionId(undefined)
    },
    [setActiveAnswerStatus, setQuestionId]
  )
  const onDifficultySectionClick = useCallback(
    (difficulty: QuestionDifficulty) => {
      setActiveDifficulty(difficulty)
      setQuestionId(undefined)
    },
    [setActiveDifficulty, setQuestionId]
  )
  const submissionSummary = useMemo(() => {
    return countAllTopicsSubmissions(topics, activeDifficulty)
  }, [activeDifficulty, topics])

  const filterQuestions = useCallback(({id}: Question) => id.includes(section.id), [section.id])

  return (
    <>
      <SectionTopContainer
        actionLoadingStatus={status}
        isLoading={!actions || !topics}
        sideCardComponent={
          activeAnswerStatus ? (
            <QuestionListSideCard
              difficulty={submissionSummary.difficulty}
              customQuestionsFilterFunction={filterQuestions}
            />
          ) : (
            <PlantSideCard
              onSectionRowClick={(id: string) => {
                navigate(
                  generatePath(PLANT_SUB_SECTION, {
                    plantId,
                    sectionId: section?.id,
                    topicId: id
                  })
                )
              }}
              sections={topics}
              actions={(actions || []).filter((a) => a.status !== 'done')}
            />
          )
        }
      >
        <CompletionCard
          activeDifficulty={activeDifficulty}
          onScoreSectionClick={onScoreSectionClick}
          onDifficultySectionClick={onDifficultySectionClick}
          responsiblePerson={section.responsible}
          submissionSummary={submissionSummary}
          difficultySummaries={section.difficultySummaries}
        />
      </SectionTopContainer>
    </>
  )
}
