import {GuidedToursProvider} from '@hconnect/uikit/src/lib2'
import React from 'react'

import {usePOMTours} from '../hooks/usePOMTours'

export const POMToursProvider = ({children}: {children: React.ReactNode}) => {
  const tours = usePOMTours()

  const enabledTours = {recentChangesTour: true}

  return (
    <GuidedToursProvider tours={tours} enabledTours={enabledTours}>
      {children}
    </GuidedToursProvider>
  )
}
