import {useParams} from 'react-router'

import {PathParameters} from '../../common/routing'

export const useSectionId = (): string => {
  const id = useParams<PathParameters>().sectionId
  if (!id) {
    throw new Error('The sectionId is not set in the URL.')
  }

  return id
}
