import {QuestionAnswer, RequestFunc} from '../../types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'

const putAnswerImpl: RequestFunc<QuestionAnswer, QuestionAnswer> = async ({
  plantId,
  questionId,
  answer
}) => {
  await dfApi.put<void>(`/pom-questionnaires/${plantId}/questions/${questionId}/submissions`, {
    answer
  })
  return {plantId, questionId, answer}
}
export const putAnswer = loggingDecorator(putAnswerImpl)
