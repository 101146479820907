import {ErrorMessage, Loader} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {NavigateFunction, generatePath, useNavigate} from 'react-router-dom'

import {
  PlantViewOption,
  extractPlantLocations,
  mapToDashboardEntries,
  getPlantRedirectData
} from '../common/plantView'
import {DASHBOARD} from '../common/routing'
import {LocationTreeTable} from '../components/plantsOverview/LocationTreeTable'
import {PlantTable} from '../components/plantsOverview/PlantTable'
import {useAllLocationsStats} from '../hooks/api/useAllLocationsStats'

export const PlantsOverviewTableContainer: React.FC<{plantView: PlantViewOption}> = ({
  plantView
}) => {
  const {t} = useTranslation()
  const navigate: NavigateFunction = useNavigate()
  const {data, isError, isLoading} = useAllLocationsStats()
  const hierarchicalData = useMemo(() => mapToDashboardEntries(data ?? []), [data])
  const flatData = useMemo(() => extractPlantLocations(data ?? []), [data])
  const {showPlantOverview, plantId: onlyAllowedPlantId} = useMemo(
    () => getPlantRedirectData(data),
    [data]
  )

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <ErrorMessage>{t('errors.plantsNotFetched')}</ErrorMessage>
  }

  if (isEmpty(data)) {
    return (
      <ErrorMessage>
        <Trans i18nKey={'errors.noPlantDataAvailable'} />
      </ErrorMessage>
    )
  }

  if (!showPlantOverview && onlyAllowedPlantId) {
    navigate(generatePath(DASHBOARD, {plantId: onlyAllowedPlantId}))
  }

  // Show the component that corresponds to the plant view option
  // It would have been shorter to use a ternary operator, but this causes re-rendering of larger swathes of the screen.
  // Therefore, the components not needed are just made invisible.
  return (
    <>
      <Box display={plantView === 'plantList' ? 'initial' : 'none'}>
        <PlantTable plants={flatData} />
      </Box>
      <Box display={plantView === 'regionalStructure' ? 'initial' : 'none'}>
        <LocationTreeTable entries={hierarchicalData} />
      </Box>
    </>
  )
}
