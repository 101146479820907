import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {
  Button,
  useMediaQuery,
  Theme,
  Popover,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box
} from '@mui/material'
import React from 'react'

import {buttonOnBlueSx} from './styles'

export interface FilterOption<T> {
  id: T
  label: string
}

interface FilterButtonProps<T> {
  caption?: string
  options: FilterOption<T>[]
  selectedIds: T[]
  onOptionChange?: (optionId: T, checked: boolean) => void
}

export const FilterButton = <T extends string>({
  caption = '',
  options,
  onOptionChange,
  selectedIds
}: FilterButtonProps<T>): React.ReactElement => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'filter-button-popover' : undefined

  return (
    <>
      <Button
        data-test-id={'add-filter-button'}
        variant={'text'}
        aria-describedby={id}
        onClick={handleClick}
        sx={buttonOnBlueSx}
        startIcon={isMobile ? undefined : <FilterAltOutlinedIcon />}
      >
        {isMobile ? <FilterAltOutlinedIcon /> : caption}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box p={3}>
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    data-test-id={`filter-button-item-${option.id}`}
                    checked={selectedIds.includes(option.id)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onOptionChange?.(option.id, event.target.checked)
                    }}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </Box>
      </Popover>
    </>
  )
}
