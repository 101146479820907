import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Public, ViewList} from '@mui/icons-material'
import {ToggleButtonGroup, ToggleButton, SvgIconProps, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlantViewOption, PLANT_VIEWS} from '../../common/plantView'

interface PlantsViewToggleProps {
  value: PlantViewOption
  onChange: (value: PlantViewOption) => void
}

const PlantViewButtonIcon: React.FC<SvgIconProps & {plantView: PlantViewOption}> = ({
  plantView,
  ...props
}) => {
  if (plantView === 'regionalStructure') return <Public {...props} />
  if (plantView === 'plantList') return <ViewList {...props} />
  return null
}

export const PlantsViewToggle: React.FC<PlantsViewToggleProps> = ({value, onChange}) => {
  const {t} = useTranslation()
  const onValueChanged = (
    event: React.MouseEvent<HTMLElement>,
    plantView: PlantViewOption | null
  ) => {
    if (plantView) {
      onChange(plantView)
    }
  }

  return (
    <ToggleButtonGroup
      color={'primary'}
      exclusive
      value={value}
      onChange={onValueChanged}
      aria-label={'plantViewToggle.ariaLabelToggle'}
      sx={{border: 1, borderColor: customThemeConstants().palette.mediumInputBorder}}
    >
      {PLANT_VIEWS.map((plantView) => (
        <ToggleButton
          sx={(theme) => ({
            '.MuiToggleButtonGroup-grouped&:not(.Mui-selected)': {
              color: theme.palette.common.white,
              '&:hover': {
                color: theme.palette.text.primary
              }
            }
          })}
          key={plantView}
          value={plantView}
          data-test-id={`plants-view-toggle-btn-${plantView}`}
        >
          <PlantViewButtonIcon plantView={plantView} />
          <Typography variant={'button'} ml={1}>
            {t(`plantViewToggle.${plantView}`)}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
