import {
  CollapsableRow,
  expandableTableHeaderCellSx,
  ExpandCollapseButton
} from '@hconnect/uikit/src/lib2'
import {Table, TableContainer, TableBody, TableCell, TableHead, TableRow, Box} from '@mui/material'
import {isEqual} from 'lodash'
import React, {useState, useMemo, useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {DASHBOARD} from '../../common/routing'
import {treeCountWithKey, mapToFlatListWithKey} from '../../common/treeUtils'
import {LocationTreeTableEntry, LocationType} from '../../types'

import {
  renderPlantTableCell,
  PLANT_COLUMN_IDS,
  getScoreCellStyle,
  SIDE_PROGRESS_COLUMN_PADDING,
  MIDDLE_PROGRESS_COLUMN_PADDING
} from './plantTableUtils'

export interface LocationTreeTableProps {
  entries: LocationTreeTableEntry[]
}

const LocationTreeTableComp: React.FC<LocationTreeTableProps> = ({entries}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const nodeCount = useMemo(() => treeCountWithKey('subItems', entries), [entries])
  const allEntryIds = useMemo(
    () => mapToFlatListWithKey('subItems', entries).map((entry) => entry.id),
    [entries]
  )
  const [expandedItems, setExpandedItems] = useState<string[]>([])

  const allExpanded = expandedItems.length === nodeCount
  const onItemToggled = useCallback((item: LocationTreeTableEntry) => {
    setExpandedItems((expanded) =>
      expanded.includes(item.id)
        ? expanded.filter((itemId) => itemId !== item.id)
        : [...expanded, item.id]
    )
  }, [])
  const toggleExpandCollapseAll = useCallback(() => {
    allExpanded ? setExpandedItems([]) : setExpandedItems(allEntryIds)
  }, [allEntryIds, allExpanded])
  const isItemExpanded = useCallback(
    (it: LocationTreeTableEntry) => expandedItems.includes(it.id),
    [expandedItems]
  )

  const getItemProps = useCallback(
    (item) =>
      item.type === LocationType.Plant
        ? {
            onClick: (item) => {
              const url = generatePath(DASHBOARD, {plantId: item.id})
              navigate(url)
            }
          }
        : {},
    [navigate]
  )

  return (
    <Box data-test-id="dashboardTable-wrapper">
      <Box mb={2}>
        <ExpandCollapseButton
          expanded={allExpanded}
          expandLabel={t('dashboardTable.button.expand')}
          collapseLabel={t('dashboardTable.button.compress')}
          onClick={toggleExpandCollapseAll}
        />
      </Box>
      <TableContainer data-test-id="dashboardTable-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box sx={expandableTableHeaderCellSx(true)}>{t('dashboardTable.location')}</Box>
              </TableCell>
              <TableCell sx={{pr: SIDE_PROGRESS_COLUMN_PADDING}}>{t('headings.basic')}</TableCell>
              <TableCell sx={{px: MIDDLE_PROGRESS_COLUMN_PADDING}}>
                {t('headings.advanced')}
              </TableCell>
              <TableCell sx={{pl: SIDE_PROGRESS_COLUMN_PADDING}}>
                {t('headings.excellence')}
              </TableCell>
              <TableCell>{t('dashboardTable.responsible')}</TableCell>
              <TableCell>{t('dashboardTable.lastUpdate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((item) => (
              <CollapsableRow
                key={item.id}
                item={item}
                columnKeys={PLANT_COLUMN_IDS}
                renderCell={renderPlantTableCell}
                itemPropsFn={getItemProps}
                collapseToggleTestIdGenerator={(itemId) => `dashboardTable-toggle-${itemId}`}
                isItemExpanded={isItemExpanded}
                onExpandToggleClicked={onItemToggled}
                getCellStyle={getScoreCellStyle}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export const LocationTreeTable = React.memo(LocationTreeTableComp, isEqual)
