import {useParams, useLocation, matchPath} from 'react-router'

import {plantRoutes, PathParameters} from '../../common/routing'

export const usePlantId = (): string => {
  const {plantId} = useParams<PathParameters>()
  if (!plantId) {
    throw new Error('The plantId is not set in the URL.')
  }

  return plantId
}

export const usePlantIdFromRoute = (): string | undefined => {
  const {pathname} = useLocation()
  let plantId
  for (const path of plantRoutes) {
    const match = matchPath({path}, pathname)
    if (match?.params) {
      plantId = match.params?.plantId
    }
  }
  return plantId
}
