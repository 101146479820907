export const calcMaxPageIndex = (itemCount: number, rowsPerPage: number): number =>
  Math.max(0, Math.ceil(itemCount / rowsPerPage) - 1)

export const sanitizePageIndex = ({
  pageIndex,
  rowsPerPage,
  itemCount
}: {
  pageIndex: number
  rowsPerPage: number
  itemCount: number
}): number => Math.max(0, Math.min(pageIndex, calcMaxPageIndex(itemCount, rowsPerPage)))
