import {customThemeConstants, DeletionModal, LoadingIconButton} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Box, styled, Grid} from '@mui/material'
import React, {useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {useAddQuestionAttachments} from '../../hooks/api/useAddQuestionAttachments'
import {useDeleteQuestionAttachment} from '../../hooks/api/useDeleteQuestionAttachment'
import {usePlantId} from '../../hooks/urlParameters/usePlantId'
import {Attachment} from '../../types/backend.types'
import {FilePreview} from '../common/FilePreview'

import {AddInformationHeader} from './AddInformationHeader'

interface AttachmentsProps {
  questionId: string
  attachedFiles?: Attachment[]
}

const Input = styled('input')({
  display: 'none'
})

const ATTACHMENT_HEIGHT = 120
const ATTACHMENT_WIDTH = 160

const FilePreviews: React.FC<{
  attachedFiles: Attachment[]
  onDeleteFile: (attachment: Attachment) => void
}> = ({attachedFiles, onDeleteFile}) => (
  <Box mt={2}>
    <Grid container spacing={customThemeConstants().defaultGridSpacing}>
      {attachedFiles.map((attachment) => (
        <Grid item key={attachment.id}>
          <FilePreview
            onRemoveClicked={onDeleteFile}
            height={ATTACHMENT_HEIGHT}
            width={ATTACHMENT_WIDTH}
            attachment={attachment}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
)

export const Attachments: React.FC<AttachmentsProps> = ({attachedFiles = [], questionId}) => {
  const plantId = usePlantId()
  const [dialogOpen, setDialogOpen] = useState(false)
  const attachmentToDelete = useRef<Attachment>()
  const {mutate: addAttachments, isLoading} = useAddQuestionAttachments()
  const {mutate: deleteAttachment} = useDeleteQuestionAttachment()
  const {t} = useTranslation()
  const onAttachFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if (fileList && fileList.length > 0) {
      addAttachments({plantId, questionId, files: Array.from(fileList)})
    }
    event.target.value = '' // reset the input, so the event is fired if you try to upload the same file again
  }

  const onDeleteFile = (attachment: Attachment) => {
    setDialogOpen(true)
    attachmentToDelete.current = attachment
  }

  return (
    <Box my={3}>
      <AddInformationHeader title={t('attachments.heading')}>
        <label htmlFor="upload-attachment-input">
          <Input
            disabled={isLoading}
            accept="image/*, application/pdf, application/msword, application/vnd.ms-excel"
            id="upload-attachment-input"
            multiple
            type="file"
            onChange={onAttachFile}
          />
          <LoadingIconButton
            loading={isLoading}
            data-test-id={'attachments-add-button'}
            component={'span'}
            color={'primary'}
            aria-label={t('attachments.button.ariaLabel')}
          >
            <Add />
          </LoadingIconButton>
        </label>
      </AddInformationHeader>
      <FilePreviews attachedFiles={attachedFiles} onDeleteFile={onDeleteFile} />
      <DeletionModal
        open={dialogOpen}
        message={t('attachments.deletionDialog.message')}
        cancelButtonCaption={t('button.cancel')}
        cancelButtonTestId={'attachments-delete-cancel-btn'}
        confirmButtonTestId={'attachments-delete-confirm-btn'}
        confirmButtonCaption={t('button.delete')}
        onConfirm={() => {
          if (attachmentToDelete.current) {
            deleteAttachment({plantId, questionId, attachment: attachmentToDelete.current})
          }
          setDialogOpen(false)
        }}
        onCancel={() => {
          setDialogOpen(false)
          attachmentToDelete.current = undefined
        }}
      />
    </Box>
  )
}
