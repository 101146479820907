import {AnswerStatus} from '@hconnect/common/types'
import {Menu, MenuItem} from '@mui/material'
import {toLower} from 'lodash'
import React, {useState, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {translationAriaKeyByAnswer, ANSWER_STATES} from '../../common/domain/questionnaire'

import {AnswerIconButton} from './AnswerIconButton'

interface AnswerDropDownButtonProps {
  id?: string
  selected?: AnswerStatus
  onSelected?: (answer: AnswerStatus) => void
}

const buildDataTestId = (
  btnDescr: string,
  id?: string,
  answer?: AnswerStatus
): string | undefined =>
  id ? `${id}-${btnDescr}-${answer ? toLower(answer) : 'noAnswer'}` : undefined

export const AnswerDropDownButton: React.FC<AnswerDropDownButtonProps> = ({
  id,
  selected,
  onSelected
}) => {
  const {t} = useTranslation()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorElement)
  const idButton = id && `${id}-answer-button`
  const idMenu = id && `${id}-answer-menu`

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorElement(null)
  }, [])

  const handleAnswerClicked = (answer: AnswerStatus) => {
    setAnchorElement(null)
    onSelected?.(answer)
  }

  return (
    <>
      <AnswerIconButton
        id={idButton}
        data-test-id={buildDataTestId('answer-button', id, selected)}
        answer={selected}
        onClick={handleClick}
        aria-controls={open ? idMenu : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      />
      <Menu
        id={idMenu}
        anchorEl={anchorElement}
        open={open}
        MenuListProps={{
          'aria-labelledby': idButton
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {ANSWER_STATES.map((answerState) => (
          <MenuItem key={answerState}>
            <AnswerIconButton
              data-test-id={buildDataTestId('menu-btn', id, answerState)}
              aria-label={t(translationAriaKeyByAnswer(answerState))}
              answer={answerState}
              onClick={() => handleAnswerClicked(answerState)}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
