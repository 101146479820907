import {Localization} from '@hconnect/uikit'

import {logger} from '../common/logger'

export const supportedLanguages = ['de-DE', 'en-US']

const loader = async (filename: string): Promise<Record<string, unknown> | undefined> => {
  const log = logger.context('localization', 'loader')
  try {
    return await import(`./${filename}.json`)
  } catch (err: unknown) {
    log.tags({filename, message: (err as Error)?.message}).error('Could not load translation')
    throw err
  }
}

export const initLocalization = () => {
  void Localization({
    fallbackLng: 'en-US',
    compatibilityJSON: undefined,
    supportedLngs: [...supportedLanguages],
    backend: {
      loader
    }
  })
}
