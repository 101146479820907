import {UserDto} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {QueryKeyName, getPomUsers} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'

export const usePomUsers = (
  namePattern: string,
  options?: UseQueryOptions<UserDto[], AxiosError>
) => {
  const plantId = usePlantId()
  return useQuery<UserDto[], AxiosError>(
    [QueryKeyName.GetPomUsers, namePattern, plantId],
    () => getPomUsers({namePattern, plantId}),
    options
  )
}
