import {Box, Tab, Tabs} from '@mui/material'
import React, {ReactNode} from 'react'

export enum PlantCardTab {
  Categories,
  Actions
}

export type Tab<T> = {
  index: T
  label: string
  component: ReactNode | undefined
}

interface TabSectionContainerProps<T> {
  tabs: Tab<T>[]
  initialTab: T
  getTabAction: (tab: T) => ReactNode | undefined
}

export const TabSectionContainer = <T,>({
  tabs,
  initialTab,
  getTabAction
}: TabSectionContainerProps<T>) => {
  const [currentTab, setCurrentTab] = React.useState<T>(initialTab)

  const action = getTabAction(currentTab)

  return (
    <Box
      data-test-id="actions-card"
      sx={(theme) => ({
        borderRadius: 1,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        pt: 2.5
      })}
    >
      <Box
        mb={3}
        px={3}
        minHeight={48}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Tabs
            value={currentTab}
            data-test-id="actions-card-tabs"
            aria-label=""
            sx={{minHeight: '32px'}}
          >
            {tabs.map(({index, label}) => (
              <Tab
                key={`tab-${index}`}
                label={label}
                onClick={() => setCurrentTab(index)}
                sx={{px: 0, mr: 4, py: 0.5, minHeight: '32px'}}
                data-test-id={`tab-section-${index}`}
              />
            ))}
          </Tabs>
        </Box>
        {action && (
          <Box height={48} display="flex" alignItems="flex-end">
            {action}
          </Box>
        )}
      </Box>
      {tabs.find(({index}) => index === currentTab)?.component}
    </Box>
  )
}
