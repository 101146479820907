import {AnswerStatus} from '@hconnect/common/types'
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Button, ButtonProps} from '@mui/material'
import React from 'react'

import {colorByAnswer} from '../../common/domain/questionnaire'
import {WithTestId} from '../../types'

import {AnswerIcon} from './AnswerIcon'

interface AnswerIconButtonProps extends ButtonProps {
  answer?: AnswerStatus
}

export const AnswerIconButton: React.FC<AnswerIconButtonProps & WithTestId> = ({
  answer,
  'data-test-id': dataTestId,
  sx,
  ...props
}) => (
  <Button
    data-test-id={dataTestId}
    variant={!answer || answer === AnswerStatus.Empty ? 'outlined' : 'contained'}
    color={colorByAnswer(answer) ?? 'secondary'}
    sx={mergeSx({width: 40, height: 40, minWidth: 0}, sx)}
    {...props}
  >
    <AnswerIcon answer={answer} />
  </Button>
)
