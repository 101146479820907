import {RoleType} from '@hconnect/apiclient'
import {
  UserDto,
  AnswerStatus,
  QuestionDifficulty,
  DifficultySummaries
} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import type {SortDirection} from '@mui/material'

import {
  LocationDtoType,
  Attachment,
  ActionSource,
  ActionStatusType,
  CommentStatus,
  ActionItemMutation
} from './backend.types'

export interface WithId {
  id: string
}

export interface WithName {
  name: string
}

export interface WithScoring {
  score: number
  completion: number
  difficultySummaries: DifficultySummaries
}

export interface WithStats extends WithId, WithName, WithScoring {
  responsible?: UserDto
}

export interface Topic extends WithStats {
  sectionId: string
  guidanceLink?: string
  questions?: Question[]
}

export interface Question extends WithId {
  difficulty: QuestionDifficulty
  submission?: {
    answer: AnswerStatus
  }
}

export interface Section extends WithStats {
  topics: Topic[]
  guidanceLink?: string
}

export type ParentSection = Pick<Section, 'id' | 'name'>

export type ParentTopic = Pick<Topic, 'id' | 'name'>

export interface ActionItem extends WithId {
  plantId: string
  questionId: string
  title: string
  concurrencyStamp: string // contains a UUID
  description: string
  assignee?: UserDto
  creator?: UserDto
  createdAt: Date
  updatedAt: Date
  source: ActionSource
  status: ActionStatusType
  shifthandoverTaskId: string
  dueDate: Date
  attachments: Attachment[]
  topic?: ParentTopic
  section?: ParentSection
}

export type ActionItemKey = keyof (ActionItem & ParentTopic)

export interface Plant extends WithStats {
  sections: Section[]
  timezone: string
}

export interface PomUser {
  email: string
  userId: string
  name: string
  roles: RoleType[]
}

export const LocationType = {
  ...LocationDtoType,
  Plant: 'plant'
} as const

export type LocationType = Uncapitalize<keyof typeof LocationType>

export interface LocationStats extends WithStats {
  countryCode?: string // if the type is country
  type: LocationType
  lastUpdate?: Date
  subLocations: LocationStats[]
}

export interface PlantLocationStats extends LocationStats {
  type: 'plant'
  countryCode?: undefined
  timezone?: string
  subLocations: never[]
}

export type CreateActionItem = Omit<ActionItemMutation, 'assigneeId' | 'dueDate'> & {
  assignee?: UserDto
  dueDate: Date
}

export interface PomComment {
  id: string
  createdOn: Date
  updatedOn?: Date
  createdBy: string
  updatedBy?: string
  status: CommentStatus
  referenceId: string // what the comment refers to, e.g. a question ID
  value: string
}

export type ActionFilterParameters = {
  title?: string
  sources?: ActionSource[]
  statuses?: ActionStatusType[]
  questionCategories?: QuestionDifficulty[]
  sectionIds?: string[]
  topicIds?: string[]
  questionIds?: string[]
  assignees?: string[]
  dueDateRange?: DateRange<Date>
}

export interface FilteredActionsParams extends ActionFilterParameters {
  plantId: string
  page: number
  rowsPerPage: number
  sortByKey?: ActionItemKey
  order?: SortDirection
}

export interface PaginatedList<T> {
  list: T[]
  total: number
  page: number
}

export interface QuestionAnswer {
  plantId: string
  questionId: string
  answer: AnswerStatus
}
