import {parseIsoDateStringToDate} from '@hconnect/common/dates/ISO8601Helpers'
import {PlantDto} from '@hconnect/common/types'
import {isEmpty} from 'lodash'

import {LocationStats, PlantLocationStats, RequestFunc} from '../../types'

import {dfApi} from './apiClient'
import {loggingDecorator} from './decorators'
import {convertScoring} from './scoring'
import {LocationDto} from './types'

export const convertPlantDtoToLocationStats = (plantDto: PlantDto): PlantLocationStats => ({
  ...convertScoring(plantDto.scoring),
  id: plantDto.plantId,
  name: plantDto.name,
  type: 'plant',
  responsible: plantDto.responsible,
  lastUpdate: plantDto.lastUpdate ? parseIsoDateStringToDate(plantDto.lastUpdate) : undefined,
  timezone: plantDto.timezone,
  subLocations: []
})

export const convertLocationDto = (dto: LocationDto): LocationStats => ({
  ...convertScoring(dto.scoring),
  id: dto.locationId,
  name: dto.name,
  responsible: dto.responsible,
  lastUpdate: dto.lastUpdate ? parseIsoDateStringToDate(dto.lastUpdate) : undefined,
  type: dto.type,
  subLocations: isEmpty(dto.children)
    ? dto.plants.map((plant) => convertPlantDtoToLocationStats(plant))
    : dto.children.map((child) => convertLocationDto(child)),
  countryCode: dto.countryCode
})

const getLocationsStatsImpl: RequestFunc<void, LocationStats[]> = async () => {
  const {data} = await dfApi.get<LocationDto[]>('/pom-questionnaires/locations/plant-hierarchy')
  return data.map(convertLocationDto)
}
export const getLocationsStats = loggingDecorator(getLocationsStatsImpl)

const downloadHierarchySubmissionsImpl: RequestFunc<void, Blob> = async () => {
  const {data} = await dfApi.get<Blob>(
    '/pom-questionnaires/locations/plant-hierarchy/excel-sheet',
    {
      responseType: 'blob'
    }
  )
  return data
}

export const downloadHierarchySubmissions = loggingDecorator(downloadHierarchySubmissionsImpl)
