import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'
import {DIFFICULTY_LABEL_KEY} from '@hconnect/common/utils'
import {customThemeConstants, ScoreItem} from '@hconnect/uikit/src/lib2'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import StarIcon from '@mui/icons-material/Star'
import {Rating, Box} from '@mui/material'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {ANSWER_STATUS_LABEL_COLOR_MAP} from '../../common/domain/questionnaire'
import {useQuestionState} from '../../hooks/useQuestionState'
import {SubmissionSummary} from '../../utils'

interface ScoreSectionProps {
  submissionSummary: SubmissionSummary
  onScoreSectionClick?: (status?: AnswerStatus) => void
  'data-test-id'?: string
}

const checkAnswerStatus = (
  desiredStatus: AnswerStatus,
  activeStatus?: AnswerStatus
): AnswerStatus | undefined => (activeStatus === desiredStatus ? undefined : desiredStatus)

const SCORE_VALUE: Record<QuestionDifficulty, number> = {
  [QuestionDifficulty.Basic]: 1,
  [QuestionDifficulty.Advanced]: 2,
  [QuestionDifficulty.Excellence]: 3
}

export const ScoreSection: FC<ScoreSectionProps> = ({submissionSummary, onScoreSectionClick}) => {
  const {t} = useTranslation()
  const [{activeAnswerStatus}] = useQuestionState()

  return (
    <Box my={3} display="flex" flexWrap="wrap" gap={1.5} alignItems="center">
      <ScoreItem
        header={t(DIFFICULTY_LABEL_KEY[submissionSummary.difficulty])}
        borderLess
        sx={{mr: 1.5}}
        label={
          <Rating
            readOnly
            name="read-only"
            value={SCORE_VALUE[submissionSummary.difficulty]}
            max={3}
            size="small"
            sx={{color: 'text.primary'}}
            emptyIcon={
              <StarIcon
                style={{color: customThemeConstants().palette.buttonFillColor}}
                fontSize="inherit"
              />
            }
          />
        }
        data-test-id="score-section-indicator"
      />

      <ScoreItem
        header={submissionSummary.positive}
        headerSx={{color: ANSWER_STATUS_LABEL_COLOR_MAP[AnswerStatus.Positive]}}
        label={
          <>
            <CheckIcon sx={{fontSize: 16, mr: 0.5}} />
            {t('answersStatistics.positive')}
          </>
        }
        active={activeAnswerStatus === AnswerStatus.Positive}
        onClick={
          onScoreSectionClick &&
          (() => onScoreSectionClick(checkAnswerStatus(AnswerStatus.Positive, activeAnswerStatus)))
        }
        data-test-id="score-section-positive"
      />
      <ScoreItem
        header={submissionSummary.negative}
        headerSx={{color: ANSWER_STATUS_LABEL_COLOR_MAP[AnswerStatus.Negative]}}
        label={
          <>
            <CloseIcon sx={{fontSize: 16, mr: 0.5}} />
            {t('answersStatistics.negative')}
          </>
        }
        active={activeAnswerStatus === AnswerStatus.Negative}
        onClick={
          onScoreSectionClick &&
          (() => onScoreSectionClick(checkAnswerStatus(AnswerStatus.Negative, activeAnswerStatus)))
        }
        data-test-id="score-section-negative"
      />
      <ScoreItem
        header={submissionSummary.empty}
        headerSx={{color: ANSWER_STATUS_LABEL_COLOR_MAP[AnswerStatus.Empty]}}
        label={
          <>
            <CloseIcon sx={{fontSize: 16, mr: 0.5}} />
            {t('answersStatistics.open')}
          </>
        }
        active={activeAnswerStatus === AnswerStatus.Empty}
        onClick={
          onScoreSectionClick &&
          (() => onScoreSectionClick(checkAnswerStatus(AnswerStatus.Empty, activeAnswerStatus)))
        }
        data-test-id="score-section-empty"
      />
      <ScoreItem
        header={submissionSummary.notAvailable}
        headerSx={{color: ANSWER_STATUS_LABEL_COLOR_MAP[AnswerStatus.NotAvailable]}}
        label={
          <>
            <CloseIcon sx={{fontSize: 16, mr: 0.5}} />
            {t('answersStatistics.notAvailable')}
          </>
        }
        active={activeAnswerStatus === AnswerStatus.NotAvailable}
        onClick={
          onScoreSectionClick &&
          (() =>
            onScoreSectionClick(checkAnswerStatus(AnswerStatus.NotAvailable, activeAnswerStatus)))
        }
        data-test-id="score-section-notAvailable"
      />
    </Box>
  )
}
