import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useQueryClient, useMutation} from 'react-query'

import {deleteAttachment, QueryKeyName} from '../../common/backend'
import {Attachment} from '../../types/backend.types'

interface DeleteAttachmentArgs {
  plantId: string
  questionId: string
  attachment: Attachment
}

export const useDeleteQuestionAttachment = () => {
  const {t} = useTranslation()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    ({plantId, questionId, attachment}: DeleteAttachmentArgs) => {
      return deleteAttachment({plantId, questionId, documentId: attachment.url})
    },
    {
      onSuccess: () => {
        void client.invalidateQueries(QueryKeyName.GetQuestions)
        enqueueSnackbar(t('attachments.deleted.success'), {
          variant: 'success'
        })
      },
      onError: () => {
        enqueueSnackbar(t('attachments.deleted.error'), {variant: 'error'})
      }
    }
  )
}
